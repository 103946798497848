import React, { useState } from 'react';
import Select from 'react-select';

export default function SelectField({ item }) {
    const [selectedValue, setSelectedValue] = useState(item.value);
    return (
        <div className={`w-full`}>
            <div className={`text-13 mb-2`}>{item.label}</div>
            <Select 
                options={item.options} 
                defaultValue={item.options.find(option => option.value === item.value)}
                onChange={(selectedOption) => {
                    setSelectedValue(selectedOption.value);
                    item.value = selectedOption.value;
                }} 
                styles={{
                    control: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                    option: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                }}
            />
        </div>
    );
}