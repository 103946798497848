import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import SearchBox from "../../../components/SearchBox";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination";
import AlertStockFilterTab from "../../../components/Product/AlertStock/AlertStockFilterTab";
import AlertStockTable from "../../../components/Tables/AlertStockTable";
import AlertStockFilterBox from "../../../components/AlertStock/AlertStockFilterBox";

function AlertStock() {

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({
        status: 'unread'
    })

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.stock.alert.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);


    const filters1 = [
        {
            title: 'عادی',
            quantity: 1000,
            active: true
        }, {
            title: 'پیش سفارش',
            quantity: 100
        },
    ]
    const filters2 = [
        {
            title: 'خوانده شده',
            quantity: 1000,
            active: true
        },
        {
            title: 'خوانده نشده',
            quantity: 1000,
            active: false
        },
        {
            title: 'در انتظار',
            quantity: 1000,
            active: false
        },
    ]


    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">اطلاع رسانی موجودی کالا</span></div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <SearchBox SearchByOptions={[
                            {
                                label:"نام محصول",
                                value:"product_title"
                            },
                            {
                                label:"نام دسته بندی",
                                value:"categpry_title"
                            },
                            {
                                label:"نام برند",
                                value:"brand_title"
                            }
                        ]} SearchByDefault={`product_title`} SetSearchQuery={setSearchQuery} SearchIn={true}/>
                    </div>
                </div>

                <AlertStockFilterBox setFilterQuery={setFilterQuery}/>

                <div className="main-content p-1 mt-3">
                    {data?.meta_data?.stats && <AlertStockFilterTab selectedTab={'all'} setSelectedTab={ (s) => {
                        if ( s === "default"  ){
                            filterQuery.is_preorder = false;
                        }else if ( s === "preorder"  ){
                            filterQuery.is_preorder = true;
                        }else{
                            filterQuery.is_preorder = ''
                        }
                        setFilterQuery(filterQuery);
                        setUpdateData(Date.now().toString());
                    } } options={data.meta_data.stats}/>}

                    {data && <AlertStockTable setUpdateData={setUpdateData} tableData={tableData}/>}
                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}

export default AlertStock