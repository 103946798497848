import ApiRoute from "../../helper/api";
import {getDateAsReadableFormat} from "../../helper/general";
import { Link } from "react-router-dom";
import Modal from "../Utilities/Modal";
import { useState } from "react";

function IntegrateTaskTable(props) {
    const [requestModal, setRequestModal] = useState(false)
    const [responseModal, setResponseModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const tableTitles = [
        'سرویس',
        'شناسه ثالث',
        'وضعیت',
        'تاریخ',
        '',
    ]

    function getServiceName(service){
        if ( service === "App\\ThirdPartyComponent\\SnappShop\\Manager\\Job\\SnappShopSendJob" ){
            return "اسنپ شاپ"
        }else if ( service === "App\\ThirdPartyComponent\\DgShahrShop\\Manager\\Job\\DgShahrShopSendJob" ){
            return "دیجی شهر"
        }
    }

    function getStateName(state){
        if ( state === "success" ){
            return "موفق"
        }else if ( state === "failed" ){
            return "ناموفق"
        }else if ( state === "pending" ){
            return "در انتظار"
        }
    }

    async function sendRequest(tableData){
        const res = await ApiRoute.integrateTask.send.fn(tableData._id)
        props.setUpdateData(Date.now().toString())

    }

    return (
        <>
        <table className="table-auto w-full block lg:table mt-4">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map(tableData => (
                    <tr className={`text-xs py-2`} key={tableData._id}>
                 
                        <td>{getServiceName(tableData.class)}</td>
                        <td>{tableData.local_id}</td>
                        <td>{getStateName(tableData.state)}</td>
                        <td>{getDateAsReadableFormat(tableData.created_at, true)}</td>
                        <td>
                            {tableData.state !== 'pending' && <button onClick={() => {setRequestModal(true); setModalData(tableData)}} className="btn btn-primary ml-2">مشاهده درخواست</button>}
                            {tableData.state !== 'pending' && <button onClick={() => {setResponseModal(true); setModalData(tableData)}} className="btn btn-primary ml-2">مشاهده پاسخ</button>}
                            {(tableData.state === "failed" || tableData.state === "pending") && <button onClick={async () => {await sendRequest(tableData)}} className="btn btn-primary">ارسال مجدد</button>}
                        </td>
                      
                    </tr>
                )
            )}
            </tbody>
        </table>

        <Modal isOpen={requestModal} onClose={() => {setRequestModal(false)}}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">درخواست</span>
                </div>

                <pre className="w-full min-h-[200px] whitespace-pre-line overflow-scroll text-left" style={{direction:'ltr'}} dangerouslySetInnerHTML={{__html:JSON.stringify(modalData?.body)}}></pre>

            </div>
        </Modal>

        <Modal isOpen={responseModal} onClose={() => {setResponseModal(false)}}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">پاسخ</span>
                </div>

                <pre className="w-full min-h-[200px] whitespace-pre-line overflow-scroll text-left" style={{direction:'ltr'}} dangerouslySetInnerHTML={{__html:modalData?.response}}></pre>

            </div>
        </Modal>

        </>
    )
}

export default IntegrateTaskTable