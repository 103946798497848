import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";
import {Line} from 'react-chartjs-2';
import moment from "jalali-moment";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

const PriceChart = ({labels, regularPrices, salePrices, prices, stockStatus}) => {
    const dates = labels.map(date => moment(date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD'))
    const data = {
        labels: dates,
        datasets: [
            {
                label: 'قیمت عادی',
                data: regularPrices,
                borderColor: 'rgb(132, 84, 255)',
                fill: false,
                yAxisID: 'y',
                tension: 0.4,
            },
            {
                label: 'قیمت ویژه',
                data: salePrices,
                borderColor: 'rgb(255, 99, 132)',
                fill: false,
                backgroundColor: 'red',
                yAxisID: 'y',
                tension: 0.4,
            },
            {
                label: 'قیمت',
                data: prices,
                borderColor: 'rgb(75, 192, 192)',
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                yAxisID: 'y',
                tension: 0.4,
            },
            {
                label: 'موجودی',
                data: stockStatus,
                borderColor: 'rgb(255, 206, 86)',
                backgroundColor: 'rgb(255, 206, 86)',
                fill: false,
                yAxisID: 'y1',
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 3,
                pointHoverRadius: 5
            },
        ],
        responsive: true,
        rtl: true
    };
    const options = {
        scales: {
            x: {
                ticks: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                },
            },
            y: {
                position: 'right',
                ticks: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                    callback: function (value, index, values) {
                        return value + ' تومان ';
                    },
                },
            },
            y1: {
                position: 'left',
                ticks: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                    callback: function (value, index, values) {
                        return value + ' عدد';
                    },
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            tooltip: {
                titleFont: {
                    family: 'IRANYekanXFaNum',
                },
                bodyFont: {
                    family: 'IRANYekanXFaNum',
                },
                rtl: true,
            },
            legend: {
                labels: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                },
                rtl: true
            },
        },
    };
    return (
        <div>
            <Line data={data} options={options} className="priceChart" />
        </div>
    )
}

export default PriceChart;