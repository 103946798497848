import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPrint} from "@fortawesome/free-solid-svg-icons";
import Toman from "../../Utilities/Toman";
import {Link, useNavigate} from "react-router-dom";
import Modal from "../../Utilities/Modal";
import React, {useState} from "react";
import ProductSelect from "../../Product/ProductSelect";
import OrderItemProductModal from "../Modal/Edit/OrderItemProductModal";
import OrderItemShipmentModal from "../Modal/Edit/OrderItemShipmentModal";
import OrderItemCouponModal from "../Modal/Edit/OrderItemCouponModal";
import OrderItemDepositModal from "../Modal/Edit/OrderItemDepositModal";
import OrderConvertToShippingInPersonModal from "../Modal/Edit/OrderConvertToShippingInPersonModal";
import OrderConvertToDispatchModal from "../Modal/Edit/OrderConvertToDispatchModal";
import OrderPrintList from "../../../helper/order_print_list";
import OrderDeliveryTime from "./Sidebar/OrderDeliveryTime";
import {HasAccessComponent} from "../../../helper/permissions";
import ApiRoute from "../../../helper/api";
import OrderItemPrivateProductModal from "../Modal/Edit/OrderItemPrivateProductModal";
import OrderItemAdditionalRentModal from "../Modal/Edit/OrderItemAdditionalRentModal";

export default function OrderItemAction({order, setCustomAction, setUpdateState}){
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(null);
    const openModal = (name) => setIsModalOpen(name)
    const closeModal = () => setIsModalOpen(null)

    const [showDropDown, setShowDropDown] = useState(false)
    const dropDownToggle = () => setShowDropDown(!showDropDown)

    const [showDropDownSettings, setShowDropDownSettings] = useState(false)
    const dropDownSettingsToggle = () => setShowDropDownSettings(!showDropDownSettings)

    const [showDropDownPrint, setShowDropDownPrint] = useState(false)
    const dropDownPrintToggle = () => setShowDropDownPrint(!showDropDownPrint)

    function showPrint(){
     if (order.is_official){
         if (order?.metadata?.show_print_official == null){
             return true;
         }
         return order?.metadata?.show_print_official !== true;
     }
     return true;
    }

    function canAddProduct(){
        if (order.is_official && order?.metadata?.official_confirmation?.status === "submitted"){
            return false;
        }
        return true;
    }

    function ManualDepositButton(){
        return (
            <li className="py-2 px-4 cursor-pointer"
                onClick={() => {
                    openModal('add-deposit-item')
                    setShowDropDown(false)
                }}>
                <button
                    className="hover:text-purple-1"> آیتم واریز وجه
                </button>
            </li>
        )
    }

    async function handleReCalc() {
        await ApiRoute.order.sync.fn(order._id)
        setUpdateState(Date.now())
    }

    return (
        <div>
            <div hidden={(order.is_transference || order.status === "completed")} className="grid lg:grid-cols-2 xl:flex text-xs gap-3 mt-4">

                <div className="flex border border-gray-7 rounded p-2">
                    <div className="relative">
                        <button className="btn-purple p-2" onClick={dropDownSettingsToggle}> تنظیمات سفارش</button>
                        <ul className={`dropDownMenu z-30 ${showDropDownSettings ? 'show !right-[7px] !top-[40px]' : ''}`}>
                            <li className="py-2 px-4 cursor-pointer">
                                <button onClick={ async () => {
                                    const res = await ApiRoute.order.shipment.updateRentPrice.fn(order._id)
                                    if (res?.status_code === 200){
                                        setUpdateState(Date.now())
                                    }
                                } } className="hover:text-purple-1">
                                    {order?.metadata?.shipment?.rent_price ? "حذف پس کرایه" : "افزودن پس کرایه"}
                                </button>
                            </li>
                            
                            <HasAccessComponent permission={`to_in_person_delivery_after_transference order`} children={
                                !order.is_transference && <li className="py-2 px-4 cursor-pointer">
                                    {!order.is_shipping_in_person && <button onClick={() => {
                                        setIsModalOpen('convert-to-shipping-in-person')
                                    }} className="hover:text-purple-1">تبدیل به حضوری</button>}
                                    {order.is_shipping_in_person && <button onClick={() => {
                                        setIsModalOpen('convert-to-dispatch')
                                    }} className="hover:text-purple-1">تبدیل به ارسالی</button>}
                                </li>
                            }/>
                        </ul>
                    </div>
                </div>

                <div className="flex border border-gray-7 rounded p-2">
                    <div className="relative">
                        <button className="btn-purple p-2" onClick={dropDownToggle}> آیتم سفارشی
                        </button>
                        <ul className={`dropDownMenu z-30 ${showDropDown ? 'show !right-[7px] !top-[40px]' : ''}`}>

                            <HasAccessComponent showIf={ order.is_transference !== true } permission={`add_item order`} children={
                                canAddProduct() && <li className="py-2 px-4 cursor-pointer"
                                                       onClick={() => {
                                                           openModal('add-product')
                                                           setShowDropDown(false)
                                                       }}><button
                                    className="hover:text-purple-1"> محصول</button></li>
                            }/>

                           {/* <li className="py-2 px-4 cursor-pointer"
                                onClick={() => openModal('add-custom-item')}><Link
                                className="hover:text-purple-1" to=""> آیتم سفارشی</Link></li>*/}
                            {/*<li className="py-2 px-4 cursor-pointer"
                                onClick={() => openModal('add-gift-card')}><Link
                                className="hover:text-purple-1" to=""> کارت هدیه</Link></li>*/}

                            { !order.is_transference && <HasAccessComponent permissions={['add_bank_deposit_item order']} permission={`add_deposit_item order`} children={<ManualDepositButton/>}/>}
                            { order.is_transference && <HasAccessComponent permission={`add_deposit_item_after_transference order`} children={<ManualDepositButton/>}/>}

                            { !order.is_transference && <HasAccessComponent permission={`add_discount order`} children={
                                <li className="py-2 px-4 cursor-pointer"
                                    onClick={() => {
                                        openModal('add-discount-code')
                                        setShowDropDown(false)
                                    }}>
                                    <button
                                        className="hover:text-purple-1"> کد تخفیف
                                    </button>
                                </li>
                            }/> }

                            { !order.is_transference && <HasAccessComponent permission={`add_shipment order`} children={
                                <li className="py-2 px-4 cursor-pointer"
                                    onClick={() => {
                                        openModal('add-shipping')
                                        setShowDropDown(false)
                                    }}>
                                    <button
                                        className="hover:text-purple-1"> حمل و نقل
                                    </button>
                                </li>
                            }/>}

                            { !order.is_transference && <li className="py-2 px-4 cursor-pointer"
                                    onClick={() => {
                                        openModal('add-private-product')
                                        setShowDropDown(false)
                                    }}>
                                    <button className="hover:text-purple-1"> محصول معرفی نشده</button>
                            </li>}

                            { !order.is_transference && <li className="py-2 px-4 cursor-pointer"
                                    onClick={() => {
                                        openModal('add-additional-rent')
                                        setShowDropDown(false)
                                    }}>
                                    <button className="hover:text-purple-1"> کرایه مازاد</button>
                            </li>}

                        </ul>
                    </div>

                </div>

                <div className="flex border border-gray-7 rounded p-2">
                    <div className="relative">
                        <button className="btn-purple p-2" onClick={dropDownPrintToggle}>پرینت ها
                        </button>
                        <ul className={`dropDownMenu z-30 ${showDropDownPrint ? 'show !right-[7px] !top-[40px]' : ''}`}>
                            {OrderPrintList(order).map((operation, id) => {
                                if ( (operation.name === "print_official" || operation.name === "print_pre_official") && !showPrint() ){ return; }
                                return (
                                <li className="py-2 px-4 cursor-pointer"
                                    onClick={ () => operation?.submit(order._id) }><button
                                    className="hover:text-purple-1">{operation.title}</button></li>
                                )
                            })}

                        </ul>
                    </div>

                </div>

               
                <div className="flex border border-gray-7 rounded p-2">
                    <button onClick={handleReCalc} className={`bg-red-2 py-0 rounded text-white px-4`}>محاسبه مجدد</button>
                </div>
            </div>

            {isModalOpen && isModalOpen === 'add-product' && <OrderItemProductModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'add-shipping' && <OrderItemShipmentModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'add-discount-code' && <OrderItemCouponModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'add-deposit-item' && <OrderItemDepositModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'convert-to-shipping-in-person' && <OrderConvertToShippingInPersonModal event={ () => {
                navigate(`/orders/delivery-time-class?id=${order._id}`)
            } } closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'convert-to-dispatch' && <OrderConvertToDispatchModal event={ () => {
                navigate(`/orders/delivery-time-class?id=${order._id}`)
            } } closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'add-private-product' && <OrderItemPrivateProductModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {isModalOpen && isModalOpen === 'add-additional-rent' && <OrderItemAdditionalRentModal closeModal={ () => {
                closeModal()
            } } order={order} setUpdateState={setUpdateState}/>}

            {/*            <Modal isOpen={isModalOpen && isModalOpen === 'add-custom-item'} onClose={closeModal}>
                <div className="page-title mb-5"><span
                    className="inline-block bg-white pl-3 text-sm text-purple-1"> آیتم سفارشی</span></div>
                <div className="form">
                    <div className="grid grid-cols-1 lg:grid-cols-5 justify-items-stretch mb-5 gap-4">
                        <label className="col-span-4 !grid">
                            <span className="text-right mb-3 pr-1">عنوان آیتم</span>
                            <input type="text" className="!mr-0"/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-3 pr-1">نوع</span>
                            <select className="!mr-0">
                                <option value="1">نوع 1</option>
                                <option value="2">نوع 2</option>
                            </select>
                        </label>
                    </div>
                    <div className="grid grid-cols-3 items-end gap-4">
                        <label className="!grid">
                                    <span className="flex items-center mb-3">
                                          <span className="text-right pr-1">قیمت
                                    </span>
                                    <div className="inline-flex items-center mr-0.5">
                                        <span className="text-9">(</span>
                                        <Toman/>
                                        <span className="text-9">)</span>
                                    </div>
                                    </span>

                            <input type="text" className="!mr-0 w-full"/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-3 pr-1">کد آسان</span>

                            <input type="text" className="!mr-0 w-full"/>
                        </label>
                        <div>
                            <button className="btn-purple py-2 w-full"></button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isModalOpen && isModalOpen === 'add-gift-card'} onClose={closeModal}>
                <div className="page-title mb-5"><span
                    className="inline-block bg-white pl-3 text-sm text-purple-1"> کارت هدیه</span></div>
                <div className="form">
                    <div className="grid grid-cols-2 justify-items-stretch mb-5 gap-4">
                        <label className="!grid">
                            <span className="text-right mb-3 pr-1">نوع کارت هدیه</span>
                            <input type="text" className="!mr-0 w-full"/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-3 pr-1 w-full">کد کارت هدیه</span>
                            <input type="text" className="!mr-0 w-full"/>
                        </label>
                    </div>
                    <div className="grid grid-cols-3 items-end  gap-4">
                        <label className="col-span-1 !grid">
                                    <span className="flex items-center mb-3">
                                    <span className="text-right pr-1">قیمت</span>
                                    <div className="inline-flex items-center mr-0.5">
                                        <span className="text-9">(</span>
                                        <Toman/>
                                        <span className="text-9">)</span>
                                    </div>
                                    </span>

                            <input type="text" className="!mr-0"/>
                        </label>
                        <div className="col-span-1 col-start-3">
                            <button className="btn-purple py-2 w-full"></button>
                        </div>
                    </div>
                </div>
            </Modal>*/}



        </div>

    )
}