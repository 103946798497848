import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faFile, faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {formatNumberWithDots, getDateAsReadableFormat, orderPaymentTransactionLabel} from "../../../helper/general";
import Modal from "../../Utilities/Modal";
import {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {HasAccess} from "../../../helper/permissions";

export default function OrderInventoryConfirmationSubmitModal({order, closeModal, event}){

    const [text, setText] = useState('')
    const [myOrder , setMyOrder] = useState(null)
    useEffect(() => {
        ( async () => {
            const response = await ApiRoute.order.single.fn(order+"?model=\\App\\OrderManager\\Model\\OrderExpanded")
            setMyOrder(response.results)
        } )()
    }, []);
    async function handleSubmit(){
        await ApiRoute.order.action.fn("inventory-confirmation/set",order,{
            description : text
        })
        try {
            event()
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <HasAccess permission={`inventory_confirmation order`} children={
                <div>

                    <div className="page-title z-1 text-right text-sm">
                        <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">تایید انبار</span>
                    </div>

                    <div className={`flex gap-2 w-full flex-col`}>

                        <input onKeyUp={ (e) => { setText(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="اینجا بنویسد ..."/>
                        { (myOrder && myOrder?.factor?.status === "submitted" && myOrder?.is_transference) &&  <button onClick={handleSubmit} className={`btn-green rounded max-w-max py-1 px-3`}>بروزرسانی</button>}
                        { (myOrder && myOrder?.factor?.status !== "submitted" || myOrder?.is_transference !== true) && <div className={`alert-red`}>ابتدا سفارش باید به تایید تامین کننده و ثبت حواله برسد</div>}
                        {process.env.NODE_ENV === 'development' &&<button onClick={handleSubmit} className={`btn-green rounded max-w-max py-1 px-3`}>بروزرسانی</button>}
                        {myOrder === null && <div className="x2-loader"></div>}
                    </div>

                </div>
            }/>
        </Modal>
    )
}