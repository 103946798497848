import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import SelectCoordinates from "../../Coordinates/SelectCoordinates";
import { hasAccess } from "../../../helper/permissions";

function CustomerLegalInfo({customer}){
    const MySwal = withReactContent(Swal)
    const [isLoading , setIsLoading] = useState(false)
    const [coordinate , setCoordinate] = useState({})

    const [generalInfo , setGeneralInfo] = useState({
        org_name: customer.legal?.org_name,
        phone: customer.legal?.phone,
        national_number: customer.legal?.national_number,
        economic_code: customer.legal?.economic_code,
        register_number: customer.legal?.register_number,
        state: customer.legal?.state,
        city: customer.legal?.city
    })

    async function sendToServer(e) {
        setIsLoading(true)

        await ApiRoute.customer.updateLegalInfo.fn(customer._id , generalInfo)

        setIsLoading(false)
    }
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 justify-items-stretch xl:mb-10 gap-4 xl:gap-10">
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">نام سازمان</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.org_name = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.org_name}
                       placeholder="نام خانوادگی را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">کد اقتصادی</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.economic_code = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.economic_code}
                       placeholder="کد ملی را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شناسه ملی</span>
                <input className="!flex-none !mr-0 text-left" type="text"
                       onChange={ e => {
                           generalInfo.national_number = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.national_number}
                       />
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شناسه ثبت</span>
                <input className="!flex-none !mr-0 text-left" type="text"
                       onChange={ e => {
                           generalInfo.register_number = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.register_number}
                       />
            </label></div>

            <div><div className="!grid text-xs">
                <span className="text-right mb-3 pr-1">موقعیت</span>
                <SelectCoordinates multiple={false} defaultValue={ {
                    state: generalInfo.state,
                    city: generalInfo.city,
                    area:{}
                } } SetCoordinate={ (v) =>{
                    generalInfo.state = v.state[0]
                    generalInfo.city = v.city[0]
                    setGeneralInfo(generalInfo)
                } }/>
            </div></div>

            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شماره تلفن ثبت</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.phone = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.phone}
                />
            </label></div>
            {hasAccess('edit_official_data_customer') && (
                <div className="self-end xl:col-start-6">
                    <button onClick={ e => { sendToServer(e) } } disabled={isLoading} className="btn-green rounded py-2 w-full">ویرایش اطلاعات</button>
                </div>
            )}
        </div>
    )
}
export default CustomerLegalInfo