import SwitchToggle from "../Utilities/SwitchToggle";
import {useEffect, useState} from "react";
import CategorySelect from "../Product/Category/CategorySelect";
import BrandSelect from "../Product/Category/BrandSelect";

export default function FilterPriceTiming({SetFilterQuery, filterQuery}){

    const [query,setQuery] = useState({});
    const [filter,setFilter] = useState({});
    const [updateData, setUpdateData] = useState('0')
    const [applyFilter, setApplyFilter] = useState(false)

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

    useEffect(() => {
        let arr = []
        selectedCategory.map(category => arr.push(category.slug) )
        changeQuery(arr,"category")
    }, [selectedCategory]);

    useEffect(() => {
        let arr = []
        selectedBrand.map(brand => arr.push(brand.value) )
        changeQuery(arr,"brand")
    }, [selectedBrand]);

    useEffect(() => {
        setFilter(query)
    }, [query]);

    useEffect(() => {
        SetFilterQuery(filter)
    }, [updateData]);

    const handleMultiSelectCategory = (_selectedCategory) => {
        setSelectedCategory(_selectedCategory)
    }

    const handleMultiSelectBrand = (selectedBrand) => {
        setSelectedBrand(selectedBrand)
    }

    function changeQuery(value , name){
        query[name] = value;
        setQuery(query)
    }

    return (
        <div className={`show`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">
                        
                        {/*relations*/}
                        <div>
                            <label>
                                <span className="block">دسته بندی</span>
                                <CategorySelect
                                    assignItem={handleMultiSelectCategory}
                                    placeholder="انتخاب دسته"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">برند</span>
                                <BrandSelect
                                    assignItem={handleMultiSelectBrand}
                                    placeholder="انتخاب برند"
                                />
                            </label>
                        </div>
                        {/*relations*/}


                        <div className="flex items-end">
                            <button
                                onClick={ e => {
                                    setApplyFilter(true)
                                    setUpdateData(Date.now().toString())
                                }}
                                className="btn-orange w-full py-2">اعمال فیلتر</button>

                            {applyFilter && (
                                <button
                                    onClick={() => {
                                        window.location.reload()
                                        setApplyFilter(false)
                                    }}
                                    className="btn-gray w-full py-2 mr-2"
                                >
                                    حذف فیلتر
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}