import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import PricingRulesTable from "../../components/Tables/PricingRulesTable";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import CreatePriceRuleBrandModal from "../../components/Price/CreatePriceRuleBrandModal";
import CreatePriceRuleModal from "../../components/Price/CreatePriceRuleModal";
import CreatePriceRuleProductModal from "../../components/Price/CreatePriceRuleProductModal";

function PricingRules() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [createModalAsProduct, setCreateModalAsProduct] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.price.rule.index.fn({
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">قوانین قیمت گذاری</span></div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex justify-between items-center gap-3">
                    <div className="flex order-2 sm:order-1">
                        <SearchBox SearchIn={false} SetSearchQuery={setSearchQuery}/>
                        {/*<button className="btn-blue w-12 mr-3"><FontAwesomeIcon icon={faFilter}/></button>*/}
                    </div>
                    <div className="flex justify-end gap-2 order-1 sm:order-2">
                        <button className="btn-blue max-sm:w-1/2 py-2" onClick={ () => {
                            setCreateModal(true)
                        } }> افزودن قانون جدید</button>
                        <button className="btn-red max-sm:w-1/2 py-2" onClick={ () => {
                            setCreateModalAsProduct(true)
                        } }> افزودن استثنا قانون</button>
                    </div>
                </div>


                <div className="main-content p-1 mt-3">
                    {data && <PricingRulesTable tableData={tableData}/>}
                    {data && <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>}
                </div>
            </div>

            {createModal && <CreatePriceRuleModal
                closeModal={ () => {
                    setCreateModal(false);
                } } event={ (res) => {
                setUpdateData(Date.now().toString())
                setCreateModal(false);
            } }/>}

            {createModalAsProduct && <CreatePriceRuleProductModal
                closeModal={ () => {
                    setCreateModalAsProduct(false);
                } } event={ (res) => {
                setUpdateData(Date.now().toString())
                setCreateModalAsProduct(false);
            } }/>}

        </Layout>
    )
}

export default PricingRules