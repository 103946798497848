import {Calendar, DateObject} from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/layouts/mobile.css"
import {useState} from "react";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
function CustomerFilterBox(props) {
    const [status, setStatus] = useState('')
    const [legal, setLegal] = useState(null)
    const [foreigner, setForeigner] = useState(null)
    const [coordinate, setCoordinate] = useState([])
    const [applyFilter, setApplyFilter] = useState(false)
    return (
        <div className={`FilterBox ${props.showHide?'show':'hide'}`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="flex flex-wrap gap-3 text-xs">
                        <div>
                            <span className="block mb-1">موقعیت</span>
                            <SelectCoordinates multiple={false} SetCoordinate={ setCoordinate }/>
                        </div>
                        <div>
                            <label>
                                <span className="block">وضعیت اسان</span>
                                <select onChange={ e => { setStatus(e.target.value) } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="1">دارد</option>
                                    <option value="-1">ندارد</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">مشتری حقوقی</span>
                                <select onChange={ e => { setLegal(e.target.value) } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value={null}>همه</option>
                                    <option value={1}>بله</option>
                                    <option value={0}>خیر</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">اتباع</span>
                                <select onChange={ e => { setForeigner(e.target.value) } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value={null}>همه</option>
                                    <option value={'1'}>بله</option>
                                    <option value={'-1'}>خیر</option>
                                </select>
                            </label>
                        </div>

                        <div className="lg:col-span-2">
                                {/*<div>
                                    <Calendar
                                        className="rmdp-mobile"
                                        calendar={persian}
                                        locale={persian_fa}
                                        calendarPosition="bottom-right"
                                    />
                                </div>*/}

                        </div>
                        <div className="flex items-end">
                            <button onClick={ e => {
                                props.SetFilterQuery({
                                    asan_status: status,
                                    coordinate:coordinate,
                                    is_legal:legal,
                                    is_foreigner:foreigner
                                })
                                setApplyFilter(true)
                            } } className="btn-orange w-full py-2">اعمال فیلتر</button>

                            {applyFilter && (
                                <button
                                    onClick={() => {
                                        window.location.reload()
                                        setApplyFilter(false)
                                    }}
                                    className="btn-gray w-full py-2 mr-2"
                                >
                                    حذف فیلتر
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CustomerFilterBox