import ImageUploader from "../../Utilities/ImageUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faChevronDown, faChevronUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import ComponentTitle from "./Common/ComponentTitle";
import { uploadUrl } from "../../../helper/api";
import {Editor} from "@tinymce/tinymce-react";

export default function HtmlBox({component , remove , up, down , refresh = null}) {
    const [updateState, setUpdateState] = useState(1)
    const [items, setItems] = useState(component.data)
    const [open, setOpen] = useState(false)

    function sync() {
        setUpdateState(Date.now())
    }


    return (
        <div className="flex flex-auto my-3">

            <div className="w-full border border-gray-7 rounded p-1 grid gap-2">

                <ComponentTitle component={component} color={`#696969`} title={"بلوک کد"} open={open} setOpen={ setOpen } remove={remove} up={up} down={down}/>

                {open && <div>
                    
                    
                    <textarea className="bordered-input w-full text-left min-h-[200px]" dir="ltr" onChange={ e => {
                        component.data = component.data || {}
                        component.data.content = e.target.value
                    } } defaultValue={component.data.content}></textarea>

                    <select onChange={ (e) => {
                        component.layout = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <div className={`text-[13px] mt-3`}>صفحه بندی موبایل</div>
                    <select onChange={ (e) => {
                        component.layout_sm = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout_sm == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout_sm == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout_sm == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout_sm == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout_sm == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout_sm == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout_sm == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout_sm == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout_sm == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout_sm == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout_sm == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout_sm == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <div className={`flex text-13 gap-3 items-center mt-2`}>
                        <span>فاصله درونی</span>
                        <input placeholder="عدد وارد کنید" className="border rounded p-1" onChange={ e => {
                            component.attributes = component.attributes || {}
                            component.attributes.padding = e.target.value
                        } } type={`number`} defaultValue={component?.attributes?.padding}/>
                    </div>

                </div>}

            </div>
        </div>
    )
}