import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faFile, faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat, orderPaymentTransactionLabel} from "../../../helper/general";
import {Link} from "react-router-dom";

export default function OrderSummeryModal( {order, closeModal = null} ){

    const [customer, setCustomer] = useState(null)
    let [onlinePaid,setOnlinePaid] = useState(0)

    useEffect(() => {
        ( async () => {
            const res = await ApiRoute.customer.single.fn(order.customer_id)
            setCustomer(res.results)
        } )()

        order?.payment_transactions && order.payment_transactions
            .map(item => {
                if (  item.is_verify && item.payment_method === "shaparak" ){
                    setOnlinePaid(item.amount)
                }
            } )

    }, []);

    return (
        <Modal isOpen={true} onClose={closeModal} modalWidth="xl:w-1/2 h-[80vh] scrollbar-style dir-rtl">
            <div>
                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">سفارشات</span>
                </div>
                <div className="border border-gray-7 rounded-md p-1 text-right mb-3">
                    <div className="px-1.5 pt-2 pb-1">
                        <FontAwesomeIcon className="ml-1" icon={faUser}/>
                        اطلاعات مشتری
                    </div>
                    <div
                        className="border border-gray-7 rounded-md p-3 text-right grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
                        <div>
                            <span className="text-gray-12 ml-1">نام مشتری:</span>
                            <span className="text-gray-5">{customer?.personal?.first_name} {customer?.personal?.last_name}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">استان:</span>
                            <span className="text-gray-5">{customer?.main_address?.coordinates?.state?.faname}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">شهر:</span>
                            <span className="text-gray-5">{customer?.main_address?.coordinates?.city?.faname}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">همراه:</span>
                            <span className="text-gray-5">{customer?.contacts?.cellphone ?? '---'}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">:شماره اضطراری:</span>
                            <span className="text-gray-5">{customer?.contacts?.emergency_phone ?? '---'}</span>
                        </div>
                    </div>
                </div>
                <div className="border border-gray-7 rounded-md p-1 text-right mb-3">
                    <div className="px-1.5 pt-2 pb-1">
                        <FontAwesomeIcon className="ml-1" icon={faLocationPin}/>
                        اطلاعات گیرنده سفارش
                    </div>

                    <div
                        className="border border-gray-7 rounded-md p-3 text-right grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-3">
                        <div>
                            <span className="text-gray-12 ml-1">نام مشتری:</span>
                            <span className="text-gray-5">{order.address.first_name} {order.address.last_name}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">استان:</span>
                            <span className="text-gray-5">{order.address?.coordinates?.state?.faname}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">شهر:</span>
                            <span className="text-gray-5">{order.address?.coordinates?.city?.faname}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">همراه:</span>
                            <span className="text-gray-5">{order.address?.cellphone}</span>
                        </div>
                        <div className="sm:col-span-2 md:col-span-3 xl:col-span-5">
                            <span className="text-gray-12 ml-1">آدرس گیرنده:</span>
                            <span className="text-gray-5">{order.address.address.address}</span>
                        </div>
                    </div>
                </div>
                <div className="border border-gray-7 rounded-md p-1 text-right mb-3">
                    <div className="px-1.5 pt-2 pb-1">
                        <FontAwesomeIcon className="ml-1" icon={faBagShopping}/>
                        خلاصه کالاها
                    </div>

                    <div
                        className="border border-gray-7 rounded-md p-3">
                        <div
                            className="border-b border-b-gray-7 pb-3 pt-2 grid grid-cols-1 md:flex md:justify-between gap-3">
                            <div>
                                                    <span
                                                        className="text-gray-12 ml-1">زمان انتخابی برای ارسال سفارش:</span>
                                <span className="text-gray-5">
                                    { order?.delivery_time?.start_date && ((getDateAsReadableFormat(order.delivery_time.start_date)) +" - "+ (order.delivery_time.start_pick) +' تا '+ (order.delivery_time.end_pick))}
                                </span>
                            </div>
                            <div className="flex">
                                <span className="text-gray-12 ml-1">هزینه ارسال:</span>
                                <span className="text-gray-5 flex items-center">
                                                    {formatNumberWithDots(order?.amounts?.shipment ?? '0')}
                                                <Toman/>
                                                </span>
                            </div>
                        </div>
                        <div className="text-13 text-gray-12 pt-3 pb-2">
                            اقلام سفارش
                        </div>

                        { order.items && order.items.filter( item => item.item_type === "product" ).map((item,i) => (
                            <div key={i} className="md:flex justify-between my-2">
                                <div className="flex items-center">
                                    <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                    <Link to={`/inventory?id=${item.item_product._id}`} className="text-purple-2 py-1.5">{item.item_name}</Link>
                                    <span className="text-gray-4 mr-1">({item.quantity} عدد)</span>
                                </div>
                                <div className="flex items-center">
                                    {formatNumberWithDots(item.item_value)}
                                    <Toman/>
                                </div>
                            </div>
                        ) ) }

                        { order.items && order.items.filter( item => item.item_sub_type === "private_product" ).map((item,i) => (
                            <div key={i} className="md:flex justify-between my-2">
                                <div className="flex items-center">
                                    <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                    <span className="text-purple-2 py-1.5">{item.item_name}</span>
                                    <span className="text-gray-4 mr-1">({item.quantity} عدد)</span>
                                </div>
                                <div className="flex items-center">
                                    {formatNumberWithDots(item.item_value)}
                                    <Toman/>
                                </div>
                            </div>
                        ) ) }

                    </div>
                </div>
                <div className="border border-gray-7 rounded-md p-1 text-right mb-3">
                    <div className="px-1.5 pt-2 pb-1">
                        <FontAwesomeIcon className="ml-1" icon={faFile}/>
                        اطلاعات پرداخت و فاکتور
                    </div>

                    <div className="border border-purple-1 rounded-md p-3">
                        <div className="grid grid-cols-1 md:grid-cols-3">

                            <div className={`flex flex-col gap-3`}>

                                {order.payment_transactions && order.payment_transactions.filter(item => item.is_verify === true).map((item,i) => (
                                    <div key={i} className="md:border-l border-l-gray-7 py-3 grid grid-cols-1 auto-rows-max items-start gap-4">
                                        <div>
                                            <span className="text-gray-12 ml-1">نوع پرداخت:</span>
                                            <span className="text-gray-5">{orderPaymentTransactionLabel(item.payment_method)}</span>
                                        </div>
                                        <div>
                                            <span className="text-gray-12 ml-1">درگاه:</span>
                                            <span className="text-gray-5 text-[12px]">{item.payment_gateway}</span>
                                        </div>
                                        <div className="flex">
                                            <span className="text-gray-12 ml-1">مبلغ:</span>
                                            <span className="text-gray-5 flex items-center">
                                                            {formatNumberWithDots(item.amount)}
                                                            <Toman/>
                                                        </span>
                                        </div>
                                        <div>
                                            <span className="text-gray-12 ml-1">کد رهگیری:</span>
                                            <span className="text-gray-5">{item.reference_id}</span>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            <div className="md:col-span-2 md:px-3 sticky">
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">مجموع پرداخت:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.paid)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
{/*                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">کد تخفیف:</span>
                                        <span className="text-gray-5 pr-1">هدیه اولین خرید</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        55,256,100
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>*/}
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">حمل و نقل:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.shipment)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">کیف پول:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.wallet)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">جمع کل:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.total)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">کسورات:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.subtraction)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">مانده:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(order.amounts.remaining)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">پرداخت آنلاین:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        {formatNumberWithDots(onlinePaid)}
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                                <div className="md:flex justify-between my-3 group">
                                    <div className="flex items-center">
                                        <div className="w-1 h-full rounded-full bg-purple-1 ml-2"></div>
                                        <span className="text-gray-12 group-hover:text-purple-2">بستانکار:</span>
                                    </div>
                                    <div className="flex items-center group-hover:text-purple-2">
                                        0
                                        <Toman customClass="group-hover:fill-purple-2"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}