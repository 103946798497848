import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import {useEffect, useState} from "react";
import Pagination from "../../components/Pagination";
import RuleItem from "./components/RuleItem";
import ApiRoute from "../../helper/api";
import CategorySelect from "../../components/Product/Category/CategorySelect";
import BrandSelect from "../../components/Product/Category/BrandSelect";
import FilterPriceTiming from "../../components/Price/FilterPriceTiming";

function PriceUpdateRules() {

    const navigate = useNavigate()
    const [newData, setNewData] = useState({
        expiration: 1,
        brands:[],
        categories:[]
    })

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    // اضافه کردن state جدید برای نگهداری مقادیر انتخاب شده
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.price.timing.index.fn({
                quick_search : searchQuery,
                page : pageNumber,
                category : filterQuery.category,
                brand : filterQuery.brand,
                model:'\\App\\PriceManager\\Model\\PriceChangeTimingAdmin'
            })
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    async function handleSubmit(){
        await ApiRoute.price.timing.create.fn(newData)
        window.location.reload()
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">قوانین بروز رسانی قیمت</span>
                </div>

                <FilterPriceTiming SetFilterQuery={setFilterQuery} filterQuery={filterQuery}/>

                <div className="main-content py-5 px-3 mt-3">
                    <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-7 px-3 gap-4 lg:gap-8 text-xs">
                        <div className="sm:col-span-3 xl:col-span-3 items-end gap-3">
                            <label className="block pb-2">دسته بندی ها</label>
                            <div className="flex-auto">
                                <CategorySelect
                                    value={selectedCategories}
                                    assignItem={(v) => {
                                        setSelectedCategories(v);
                                        let arr = [];
                                        v.map((x) => {
                                            arr.push(x.slug);
                                        });
                                        newData.categories = arr;
                                    }}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3 xl:col-span-4 grid grid-cols-1 md:flex items-end gap-4">
                            <div className="flex-auto">
                                <label className="block pb-2">برند ها</label>
                                <div className="flex-auto">
                                    <BrandSelect assignItem={ (v) => {
                                        let arr = []
                                        v.map( (x) => {
                                            arr.push(x.value)
                                        } )
                                        newData.brands = arr
                                    } }/>
                                </div>
                            </div>
                            <div className="form">
                                <label className="block pb-2 lg:mr-3">اعتبار قیمت ( به روز )</label>
                                <input onKeyUp={ (e) => {
                                    newData.expiration = parseInt(e.target.value)
                                } } className="text-center max-lg:w-full max-lg:!mr-0" type="text"/>
                            </div>
                            <button className="btn-purple px-8 py-2" onClick={handleSubmit}>افزودن</button>
                        </div>
                    </div>

                    {data && <table className="table-auto table-borderd-tr w-full mt-4 block lg:table text-xs">
                        <thead>
                        <tr>
                            <td className="w-[42%]">دسته بندی ها</td>
                            <td className="w-[38%]">برند ها</td>
                            <td className="w-[10%]">اعتبار قیمت ( به روز )</td>
                            <td className="w-[5%]"></td>
                        </tr>
                        </thead>
                        <tbody>
                         {tableData.map( (item) => <RuleItem item={item} setUpdateData={setUpdateData} /> )}
                        </tbody>
                    </table>}

                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}

export default PriceUpdateRules