import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ApiRoute, { getEmployeeId } from '../helper/api';

function NotificationHandler() {
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await ApiRoute.notification.system.fn()
                if (response?.results?.length > 0) {
                    response.results.forEach(notification => {
                        if (notification && notification.message) {
                            if( notification.employee_id === getEmployeeId() ){
                                toast(notification.message, {
                                    type: notification.type || 'info',
                                    position: "bottom-left",
                                    autoClose: false,
                                    onClose: async () => {
                                        try {
                                            await ApiRoute.notification.close.fn(notification?.id);
                                        } catch (error) {
                                            console.error('error closing notification', error);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            } catch (error) {
                console.error('error fetching notifications', error);
            }
        };

        // چک کردن نوتیفیکیشن‌ها هر 60 ثانیه
        const interval = setInterval(fetchNotifications, 60000);
        fetchNotifications(); // اجرای اولیه

        return () => clearInterval(interval);
    }, []);

    return null; // این کامپوننت چیزی رندر نمی‌کند
}

export default NotificationHandler; 