import {useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faRectangleXmark,
    faReply,
    faTimesSquare, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {getDateAsReadableFormat} from "../helper/general";
import useToken from "../useToken";
import moment from "jalali-moment";
import ApiRoute from "../helper/api";
import SwitchToggle from "./Utilities/SwitchToggle";

function Faq({item,hasAnswer = false,setUpdateData}) {
    const { token,resetToken } = useToken();
    const [showActionMenu, setShowActionMenu] = useState(false)
    const [updateDataLocal, setUpdateDataLocal] = useState('0')
    const menuToggle = () => {
        setShowActionMenu(!showActionMenu);
    }

    const getImage = () => {
        try {
            return item.product.attachments[0]['url']
        }catch (e) {
            return null
        }
    }
    async function handleUpdate(){
        await ApiRoute.product.question.update.fn(item._id, item);
    }

    return (
        <>
            <div className={`fixed w-screen h-screen ${showActionMenu ? 'block' : 'hidden'}`}
                 onClick={menuToggle}></div>
            <div className={`comment border border-gray-7 rounded-md p-2 ${!item.approved ? 'bg-[#fbcb5914]' : ''}`}>
                <div className="grid md:flex text-xs items-center gap-4">
                    {getImage() && <img src={getImage()} width="57" alt={item.product.title}/>}
                    <div className="w-full">
                        <div className="grid max-md:gap-3 sm:grid-cols-2 lg:flex w-full lg:justify-between items-center border-b border-b-gray-7 pb-2 text-13">
                            <div>{item.product.title}</div>

                            <div className="flex gap-4 md:gap-10 justify-between items-center">
                                    <span className="text-gray-10">
                                    {getDateAsReadableFormat(item.created_at)}
                                    </span>
                                <span className="text-gray-10">
                                       کاربر: {item.customer.personal.first_name + " " + item.customer.personal.last_name}
                                    </span>

                        {/*         <SwitchToggle onSwitch={ async (c) => {
                                    item.approved = !item.approved
                                } } onOff={item.approved}/> */}
                                <div className="relative">
                                    <button onClick={menuToggle}>
                                    <span
                                        className="border border-gray-10 rounded-full w-1 h-1 aspect-square block"></span>
                                        <span
                                            className="border border-gray-10 rounded-full w-1 h-1 aspect-square block my-0.5"></span>
                                        <span
                                            className="border border-gray-10 rounded-full w-1 h-1 aspect-square block"></span>
                                    </button>

                                    <ul className={`dropDownMenu ${showActionMenu ? 'show !top-0' : '!top-3'}`}>

                                    {!item.approved && <li className="py-3 px-4 flex items-center">
                                        <button onClick={ async () => {
                                                item.approved = !item.approved
                                                await handleUpdate()
                                                setUpdateData(Date.now())
                                            } } className="hover:text-purple-1">
                                                <FontAwesomeIcon className="ml-2" icon={faCheck}/>
                                                تایید
                                            </button>
                                        </li>}

                                        <li className="py-3 px-4 flex items-center">

                                            <button onClick={ () => {
                                                item?.answers?.push({
                                                    "employee": {
                                                        "name": token?.employee?.name,
                                                        "_id": token?.employee?._id
                                                    },
                                                    "content": "پاسخ کارشناس",
                                                    "created_at": moment().format('YYYY-MM-DD HH:mm:ss'),
                                                    "updated_at": moment().format('YYYY-MM-DD HH:mm:ss'),
                                                })
                                                setUpdateDataLocal(Date.now())
                                            } } className="hover:text-purple-1">
                                                <FontAwesomeIcon className="ml-2" icon={faReply}/>
                                                پاسخ
                                            </button>
                                        </li>
                                        <li className="py-3 px-4 flex items-center">
                                            <button onClick={async () => {
                                                try {
                                                    await ApiRoute.product.question.delete.fn(item._id);
                                                    setUpdateData(Date.now())
                                                } catch (error) {
                                                    alert('خطا در حذف سوال');
                                                }
                                            }} className="hover:text-purple-1">
                                                <FontAwesomeIcon className="ml-2" icon={faTimesSquare}/>
                                                حذف سوال
                                            </button>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <input onKeyUp={ (e) => {
                    item.content = e.target.value;
                } } className="border border-gray-7 rounded bg-gray-11 px-3 py-2 w-full mt-4 text-xs" type="text"
                       defaultValue={item.content}/>

                {item?.answers?.length > 0 && item.answers.map( (_item,index) => <div key={index} className="grid md:flex items-center gap-3 mt-6 md:mt-4 text-xs">
                    <div className="flex gap-2">
                        <FontAwesomeIcon icon={faReply}/>
                        <span className="text-purple-3">
                            {_item?.employee?.name ? _item?.employee?.name : 'سیستم'}
                        </span>
                    </div>
                    <input onKeyUp={ (e) => {
                        _item.content = e.target.value;
                    } } className="border border-gray-7 rounded bg-gray-11 px-3 py-2 flex-auto" type="text"
                           defaultValue={_item.content}/>
                    <button onClick={ () => {
                        item.answers.splice(index,1)
                        setUpdateDataLocal(Date.now())
                    } } className="btn-outline-gray py-2">
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                </div> )}

                <button onClick={handleUpdate} className="btn-green mt-4 rounded py-1 px-3 text-13">
                    ذخیره تغییرات
                </button>

            </div>
        </>
    );
}

export default Faq;