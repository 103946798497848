import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SearchBox from "../components/SearchBox";
import AlertStockFilterBox from "../components/AlertStock/AlertStockFilterBox";
import AlertStockFilterTab from "../components/Product/AlertStock/AlertStockFilterTab";
import AlertStockTable from "../components/Tables/AlertStockTable";
import Pagination from "../components/Pagination";
import ApiRoute, { shopId } from "../helper/api";
import Layout from "../components/Layout";
import IntegrateTaskFilterTab from "../components/IntegrateTask/IntegrateTaskFilterTab";
import IntegrateTaskTable from "../components/IntegrateTask/IntegrateTaskTable";


function IntegrateTask() {

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.integrateTask.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);


    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">اطلاع رسانی ادغام سازی ها</span></div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <SearchBox SearchByOptions={[
                            {
                                label:"کد محصول در سرویس ثالث",
                                value:"local_id"
                            }
                        ]} SearchByDefault={`local_id`} SetSearchQuery={setSearchQuery} SearchIn={true}/>
                    </div>
                </div>

{/*                 <AlertStockFilterBox setFilterQuery={setFilterQuery}/>
 */}
                <div className="main-content p-1 mt-3">
                    {data?.meta_data?.stats && <IntegrateTaskFilterTab selectedTab={'failed'} setSelectedTab={ (s) => {
                        filterQuery.state = s
                        if(s === 'failed'){
                            filterQuery.model = "\\App\\IntegrateTaskManager\\Model\\IntegrateTask"
                        }else if(s === 'pending'){
                            filterQuery.model = "\\App\\IntegrateTaskManager\\Model\\IntegrateTask"
                        }else{
                            filterQuery.model = "\\App\\IntegrateTaskManager\\Model\\IntegrateTaskSeries"
                        }
                        setUpdateData(Date.now().toString());
                    } } options={data.meta_data.stats}/>}

                    {data && <IntegrateTaskTable setUpdateData={setUpdateData} tableData={tableData}/>}
                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}

export default IntegrateTask