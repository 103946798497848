import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsStaggered, faClone, faEdit} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useState} from "react";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots} from "../../../helper/general";
import Modal from "../../Utilities/Modal";
import SelectCoordinates from "../../Coordinates/SelectCoordinates";
import CategorySelect from "../../Product/Category/CategorySelect";
import SelectCoordinates2 from '../../Coordinates/SelectCoordinates2';


export default function ShipmentClassTable(props) {
    const tableTitles = [
        'عنوان',
        'قیمت',
        'قیمت راننده',
        'قیمت در محل',
        'وضعیت',
    ]
    const [editModal,setEditModal] = useState(false)
    const [selected,setSelected] = useState([])

    function changeSelectedItem(value , name , tableData){
        if (!selected[tableData.uid]){
            selected[tableData.uid] = [];
        }
        selected[tableData.uid][name] = value;
        setSelected(selected)

    }

    async function deleteHandle(state, city){
        alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
    }

    async function handleEdit() {
        await ApiRoute.shipment.classes.update.fn(selected._id , selected)
        props.SetUpdateData(Date.now().toString())
    }

    return (
        <>
            <table className="coupon-table table-auto w-full mt-4 block sm:table">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {tableTitles.map((item, id) => (
                        <td className="text-13 text-right text-gray-6 pb-2" key={id}>{item}</td>
                    ))}
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {
                    props.tableData.map((tableData, id) => (<tr className={`text-xs py-2`} key={id}>

                            <td className={`text-right`}>{tableData.title}</td>
                            <td className={`text-right`}>{formatNumberWithDots(tableData.price)}</td>
                            <td className={`text-right`}>{formatNumberWithDots(tableData.driver_price)}</td>
                            <td className={`text-right`}>{formatNumberWithDots(tableData.cod_price ?? 0)}</td>
                            <td className={`text-right`}>{tableData.status ? 'فعال' : 'غیرفعال'}</td>
                            <td className="flex justify-center items-center py-3">

                                <button onClick={() => {
                                    setEditModal(true)
                                    setSelected(tableData)
                                }} className="mx-2" title="ویرایش"><FontAwesomeIcon
                                    className="bg-purple-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                <button onClick={ async () => {
                                    await ApiRoute.shipment.classes.clone.fn(tableData._id)
                                    props.SetUpdateData(Date.now().toString())
                                }} className="mx-2" title="کلون"><FontAwesomeIcon
                                    className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faClone}/></button>

                                <button onClick={async () => {await deleteHandle(tableData,tableData)}} className="mx-2" title="حذف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faRemove}/></button>
                            </td>

                        </tr>)
                    )}
                </tbody>
            </table>

            <Modal modalWidth={`xl:w-1/2 h-[80vh] scrollbar-style dir-rtl`} isOpen={editModal} onClose={() => {setEditModal(false)}}>
                <div>

                    <div className="page-title z-1 text-right text-sm">
                        <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ویرایش کلاس حمل و نقل</span>
                    </div>

                    <div className={`flex gap-2 w-full flex-col`}>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>عنوان</div>
                            <input onKeyUp={ (e) => {
                                selected.title = e.target.value;
                                setSelected(selected)
                            } } defaultValue={selected.title} className={`input-x`}/>
                        </div>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>قیمت</div>
                            <input onKeyUp={ (e) => {
                                selected.price = e.target.value;
                                setSelected(selected)
                            } } dir={`ltr`} defaultValue={selected.price} className={`input-x`}/>
                        </div>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>قیمت راننده</div>
                            <input onKeyUp={ (e) => {
                                selected.driver_price = e.target.value;
                                setSelected(selected)
                            } } dir={`ltr`} defaultValue={selected.driver_price} className={`input-x`}/>
                        </div>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>قیمت در محل</div>
                            <input onKeyUp={ (e) => {
                                selected.cod_price = e.target.value;
                                setSelected(selected)
                            } } dir={`ltr`} defaultValue={selected.cod_price} className={`input-x`}/>
                        </div>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>وضعیت</div>
                            <select onChange={ (e) => {
                                selected.status = e.target.value === '1';
                                setSelected(selected)
                            } } className={`input-x`}>
                                <option selected={selected.status} value={`1`}>فعال</option>
                                <option selected={!selected.status} value={`0`}>غیرفعال</option>
                            </select>
                        </div>

                        <div className={`flex w-full text-13 flex-col gap-2`}>
                            <div>موقعیت</div>
                            {/* <SelectCoordinates refresh={selected._id} defaultValue={{
                                state:selected.state,
                                city:selected.city,
                                area:selected.area,
                            }} multiple={true} defaultValueMultiple={true} SetCoordinate={ (s) => {
                                selected.state = s.state;
                                selected.city = s.city;
                                selected.area = s.area;
                                setSelected(selected)
                            } }/> */}
                            <SelectCoordinates2 defaultValueMultiple={true} multiple={true} defaultValue={{
                                state:selected.state,
                                city:selected.city,
                                area:selected.area,
                            }} setCoordinate={ (s) => {
                                selected.state = s.state;
                                selected.city = s.city;
                                selected.area = s.area;
                                setSelected(selected)
                            } }/>
                        </div>

                        <div className={`flex w-full text-13 flex-col`}>
                            <div>دسته بندی ها</div>
                            <CategorySelect defaultValue={selected.categories} assignItem={(s) => {
                                let arr = []
                                for (const item of s) {
                                   arr.push(item.slug)
                                }
                                selected.categories = arr
                                setSelected(selected)
                            }}/>
                        </div>

                    </div>

                    <button onClick={handleEdit} className={`btn-green py-1 mt-4 rounded`}>بروزرسانی</button>


                </div>
            </Modal>

        </>
    )
}