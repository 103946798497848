import moment from "jalali-moment";
import {isPrimaryShop, shopInfo} from "./api";

export function formatNumberWithDots(number) {
    const reversedNumber = String(number).split('').reverse().join('');
    const formattedNumber = reversedNumber.replace(/(\d{3})/g, '$1.').split('').reverse().join('');
    return formattedNumber.startsWith('.') ? formattedNumber.slice(1) : formattedNumber;
}

export function formatNumberWithCama(number) {
    const reversedNumber = String(number).split('').reverse().join('');
    const formattedNumber = reversedNumber.replace(/(\d{3})/g, '$1,').split('').reverse().join('');
    return formattedNumber.startsWith(',') ? formattedNumber.slice(1) : formattedNumber;
}

export function getDateByFormat(date , format = 'dddd D MMM YYYY') {
    if (date == null || date == undefined){return null;}
    try {
        return moment(date)
            .locale('fa')
            .format(format)
    }catch (err){
        return null;
    }
}

export function getDateAsReadableFormat(date , time = false) {
    if (date == null || date == undefined){return null;}
    try {
        return moment(date)
            .locale('fa')
            .format('dddd D MMM YYYY'+(time ? ' ( HH:mm ) ' : ''))
    }catch (err){
        return null;
    }
}

export function getDateAsReadableCustomFormat(date, format = 'dddd D MMM YYYY' , time = false) {
    if (date == null || date == undefined){return null;}
    try {
        return moment(date)
            .locale('fa')
            .format(format+(time ? ' ( HH:mm ) ' : ''))
    }catch (err){
        return null;
    }
}

export function getTimeFromDate(date , time = false) {
    try {
        return moment(date)
            .format('HH:mm:ss')
    }catch (err){
        return null;
    }
}

const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}

export function orderStatusList(status = null) {
    const statusList = [
        'waiting',
        'pending',
        'approved',
        'process',
        'shipment',
        'completed',
        'refunded',
        'canceled',
        'failed',
    ];
    if (status !== null){
        for (const _status of statusList) {
            if (_status === status){return _status;}
        }
    }
    return statusList;
}

export function orderStatusLabel(str , isPreOrder = false) {
    switch (str) {
        case 'waiting':
            return isPreOrder ? 'ثبت درخواست' : 'در انتظار بررسی';
        case 'pending':
            return 'در انتظار پرداخت';
        case 'approved':
            return isPreOrder ? 'تکمیل وجه' : 'در حال پردازش';
        case 'process':
            return 'در حال انجام';
        case 'shipment':
            return 'در حال ارسال';
        case 'completed':
            return isPreOrder ? 'تکمیل پیش سفارش' : 'تکمیل شده';
        case 'refunded':
            return 'مرجوع شده';
        case 'canceled':
            return isPreOrder ? 'لغو پیش سفارش' : 'لغو شده';
        case 'failed':
            return 'ناموفق';
    }
}

export function orderPaymentTransactionLabel(str) {
    switch (str) {
        case 'shaparak':
            return 'پرداخت آنلاین';
        case 'wallet_withdraw':
            return 'کیف پول';
        default:
            return str;
    }
}

export const faNumberToEn = (text) => text.replace(/[٠-٩۰-۹]/g,a=>a.charCodeAt(0)&15);

export function getFrontEndUrl(endpoint = ''){
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:3000'+endpoint;
    }
    return 'https://www.entekhabcenter.com'+endpoint;
}
export function getDriverPrice(shipment_item){
    let arr = []
    try {
        shipment_item.item_shipment.map( (item) => {
            arr.push(item.class.driver_price)
        } )
        return Math.max(...arr)
    }catch (e) {
        return 0
    }

}

export function startLoader(){
    try{
        document.getElementById("loading-x").style.display = "grid"
    }catch (e) {

    }
}
export function stopLoader(){
    try {
        document.getElementById("loading-x").style.display = "none"
    }catch (e) {}
}

export function paymentMethodListCreateOrder(){
    if (!isPrimaryShop()){
        let list = [
            {label : "واریز به حساب (با شناسه)" , name : "bank_melli_deposit_id"},
            {label : "واریز وجه کامل" , name : "debit_card"},
            {label : "واریز پیش پرداخت با پوز" , name : "prepayment_point_of_sale"},
            {label : "پرداخت با پوز فروشگاه" , name : "point_of_sale_shop"}
        ]
        if (shopInfo('has_shaparak_payment_method')){
            list.push({label : "پرداخت آنلاین" , name : "shaparak"})
        }
        if (shopInfo('has_cod_payment_method')){
            list.push({label : "پرداخت در محل" , name : "cash_on_delivery"})
        }
        return list
    }
    return [
        {label : "پرداخت آنلاین" , name : "shaparak"},
        {label : "پرداخت در محل" , name : "cash_on_delivery"},
    ]
}

export function paymentMethodListEditOrder(order){
    let list = [
        {
            value: 'shaparak',
            label: 'پرداخت آنلاین'
        },
        {
            value: 'cash_on_delivery',
            label: 'پرداخت در محل'
        },
        {
            value: 'ezpay',
            label: 'پرداخت ایزپی'
        },
        {
            value: 'debit_card',
            label: 'واريز وجه يا کارت به کارت'
        },
        {
            value: 'point_of_sale',
            label: 'پرداخت با پوز'
        },
        {
            value: 'cheque',
            label: 'پرداخت با چک'
        }
    ]
    if(!order?.shop_info?.primary){
        if (!order?.shop_info?.has_shaparak_payment_method){
            list = list.filter(item => item.value !== 'shaparak')
        }
        if (!order?.shop_info?.has_cod_payment_method){
            list = list.filter(item => item.value !== 'cash_on_delivery')
        }
    }
    return list
}

export function storeClickAction(key){
    return sessionStorage.setItem(key,1)
}
export function hasClickAction(key){
    return sessionStorage.getItem(key)
}

export function getDriverPriceFromOrder(order){
    let driverPrices = [0]
    try {
        order.items.filter(item => item.item_type === 'shipment').forEach(item => {
            item.item_shipment.forEach(shipment => {
                driverPrices.push(shipment.class.driver_price)
            })
        })
    } catch (error) {
        return 0
    }
    return Math.max(...driverPrices)
}

export function updateVersion(){
    return "v3"
}

export function getPercentOf(target,dest)
{
    let x = dest;
    let y = target;
    if(x == 0){ return 0;}
    if(y == 0){ return 0;}
    let c = x / y * 100 - 100;
    let _return =  Math.round(c,2);
    return _return;
}