import {orderStatusLabel} from "../../../helper/general";

export default function OrderKeyHeader({order, setUpdateState}){
    return (
        <div className="page-title mb-3">
                    <span className="inline-block bg-white pl-3">
                        سفارش به شماره {order.order_key}
                        <span className={ (order.status === 'process' ? 'purple-tag' :
                            order.status === 'pending' ? 'orange-light-3-tag' :
                                order.status === 'approved' ? 'blue-light-2-tag' :
                                    order.status === 'shipment' ? 'purple-2-tag' :
                                    order.status === 'completed' ? 'green-tag' :
                                    order.status === 'refunded' ? 'gray-tag' :
                                    order.status === 'canceled' ? 'red-tag' :
                                    order.status === 'failed' ? 'gray-2-tag' : 'orange-tag') +` inline-block w-max mr-2 text-xs px-3`}>
                            {orderStatusLabel(order.status,order.is_preorder)}
                        </span>
                        {order.is_preorder && <span className={`inline-block w-max mr-2 text-xs px-3 blue-light-1-tag`}>
                            پیش سفارش
                        </span>}
                        {order.was_preorder && <span className={`inline-block w-max mr-2 text-xs px-3 blue-light-1-tag`}>
                            تبدیل شده
                        </span>}
                        {order.is_official && <span className={`inline-block w-max mr-2 text-xs px-3 purple-2-tag`}>
                            حقوقی
                        </span>}
                        {order.is_official && order?.metadata?.official_confirmation?.status === "submitted" && <span className={`inline-block w-max mr-2 text-xs px-3 green-tag`}>
                            تایید حقوقی
                        </span>}
                        {order?.metadata?.accountant_confirmation?.status === "submitted" && <span className={`inline-block w-max mr-2 text-xs px-3 green-tag`}>
                            تایید حسابدار
                        </span>}
                        {order.is_shipping_in_person && <span className={`inline-block w-max mr-2 text-xs px-3 red-tag`}>
                            حضوری
                        </span>}
                        {order.created_by_operator && <span className={`inline-block w-max mr-2 text-xs px-3 gray-2-tag`}>
                            پنل ادمین
                        </span>}
                        {order?.metadata?.shipment?.rent_price && <span className={`inline-block w-max mr-2 text-xs px-3 !text-[#3f51b5] green-tag !bg-[#3f51b53d]`}>
                            پس کرایه
                        </span>}
                    </span>
        </div>
    )
}