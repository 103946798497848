import CustomMultiSelect from "../../Utilities/CustomMultiSelect";
import Modal from "../../Utilities/Modal";
import React, {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {toast} from "react-toastify";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {getTimeFromDate} from "../../../helper/general";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLineChart, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import PriceChart from "../../Utilities/PriceChart";

export default function PriceExpiredModel({product , closeModal , onSuccess}){
    const [loading , setLoading] = useState(false)
    return (
        <Modal isOpen={true} onClose={closeModal} classes="">
            <div className="page-title z-1 mb-3 text-13"><span
                className="inline-block text-purple-1 bg-white pl-3 z-2 relative">
                    <FontAwesomeIcon className="ml-1" icon={faMoneyBill}/>
                    حذف انقضای قیمت : {product.title}
                </span>
            </div>
            

            <button onClick={ async () => {
                setLoading(true)
                const response = await ApiRoute.product.resetPriceExpired.fn(product._id)
                if (response.status_code === 200){
                    onSuccess()
                    closeModal()
                }
                setLoading(false)
            } } disabled={loading} className="btn btn-purple-1 bg-purple-1 text-white rounded p-2 w-full">حذف انقضای قیمت</button>

        </Modal>
    )
}