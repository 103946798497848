import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ApiRoute, { shopId } from "../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat, orderStatusLabel} from "../../helper/general";

export default function LatestOrder(){
    const [data,setData] = useState(null)
    useEffect(() => {
        (  async () => {
            const res = await ApiRoute.analytics.widget.latestOrder.fn({
                shop_id: shopId()
            })
            if (res?.status_code === 200){
                setData(res.results.data)
            }
        } )()
    }, []);
    return (
        <div className="main-content pt-5 px-4 pb-3">
            <div className="text-13">سفارشات اخیر</div>
            <table className="table-auto w-full mt-4 text-11 block sm:table">
                <thead>
                <tr>
                    <td>شماره سفارش</td>
                    <td>نام مشتری</td>
                    <td>تاریخ ثبت سفارش</td>
                    <td>مبلغ سفارش</td>
                    <td>وضعیت</td>
                </tr>
                </thead>
                <tbody>

                {data && data.map( (item) => (
                    <tr className="hover:bg-gray-1">
                        <td><Link to={`/orders/edit?id=${item._id}`}
                                  className="text-purple-1 px-2 rounded hover:text-white hover:bg-purple-1">{item.order_key}</Link>
                        </td>
                        <td>{(item?.address?.first_name ?? '') + " "+ (item?.address?.last_name ?? '')}</td>
                        <td>{getDateAsReadableFormat(item.created_at)}</td>
                        <td>{formatNumberWithDots(item?.amounts?.total)}</td>
                        <td>                                <span className={item.status === 'process' ? 'purple-tag' :
                            item.status === 'pending' ? 'orange-light-3-tag' :
                                item.status === 'approved' ? 'blue-light-2-tag' :
                                    item.status === 'shipment' ? 'purple-2-tag' :
                                        item.status === 'completed' ? 'green-tag' :
                                            item.status === 'refunded' ? 'gray-tag' :
                                                item.status === 'canceled' ? 'red-tag' :
                                                    item.status === 'failed' ? 'gray-2-tag' : 'orange-tag'}>
                            {orderStatusLabel(item.status,item.is_preorder)}
                        </span></td>
                    </tr>
                ) )}

                </tbody>
            </table>
        </div>
    )
}