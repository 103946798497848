import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCartFlatbed, faCheck, faCheckCircle, faCheckToSlot,
    faCopy,
    faEllipsis, faEnvelope,
    faExclamationTriangle,
    faFile, faListCheck,
    faRotateRight,
    faSort, faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import Modal from "../Utilities/Modal";
import Toman from "../Utilities/Toman";
import {formatNumberWithDots, getDateAsReadableFormat, getDriverPrice, orderStatusLabel} from "../../helper/general";
import ApiRoute from "../../helper/api";
import DynamicTabComponent from "../Utilities/DynamicTabComponent";

function OrderTable(props) {
    const [showProductsModal,setShowProductsModal] = useState(false)
    const [products,setProducts] = useState(null)
    const [selectedOrder,setSelectedOrder] = useState(null)
    const tableTitles = [
        {title:'فروشگاه'},
        {title:'ش.سفارش'},
        {title:'وضعیت'},
        {title:'ت.حواله', sort:true, name:"transference_date"},
        {title:'ت.تحویل', sort:true, name:"delivery_time"},
        {title:'کالاها'},
        {title:'م.حواله'},
        {title:'استان', sort:true, name:"state"},
        {title:'شهر', sort:true, name:"city"},
        {title:'راننده'},
        {title:'محله', sort:true, name:"area"},
        {title:'آدرس'},
        {title:'کرایه راننده', isPrice:true},
        {title:'مانده', isPrice:true, sort:true, name:"remaining"},
        {title:'حمل و نقل', isPrice:true, sort:true, name:"shipping_price"},
    ]

    const [isModalOpen, setIsModalOpen] = useState(null);
    const openModal = (name) => setIsModalOpen(name)
    const closeModal = () => setIsModalOpen(null)

    const [expandedDescription, setExpandedDescription] = useState(null);

    const truncateText = (text, maxLength = 50) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    }

    async function showProducts(tableData) {
        const res = await ApiRoute.order.shippingProducts.fn(tableData._id)
        setShowProductsModal(true)
        setProducts(res.results)
        setSelectedOrder(tableData)
    }

    const checkStock = (tableData) => {
        let _return = false
        tableData.items.filter( (it,i) => it.item_type === "product"  ).map( (item) => {
            _return = (item.item_product.stocks.iran - item.quantity) < 0
        } )
        return _return
    }

    return (
        <>
            <table className="table-auto w-full mt-4 block 2xl:table">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {tableTitles.map((item, id) => (
                        <td onClick={ () => {
                            if (item.sort !== true) return;
                            let dir = localStorage.getItem("sort_dir") ?? "desc";
                            if ( dir === "desc" ){
                                localStorage.setItem("sort_dir", "asc")
                            }else{
                                localStorage.setItem("sort_dir", "desc")
                            }
                            props.onSort(item.name , dir)
                        } } className={ (item.sort && 'cursor-pointer text-purple-1') +` text-13 relative text-gray-6 pb-2`} key={id}>
                            {item.title}
                            {item.sort && <FontAwesomeIcon className={`mr-2`} icon={faSort}/>}
                        </td>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.tableData.map((tableData, id) => (
                    <tr className={`text-xs py-2${props.tableOptions.rowSatus ? ' before' : ''} ${(tableData?.customer_info?.asan_id > 0) ? ' before checked' : ''}`}
                        key={id}>
                        <td><span dangerouslySetInnerHTML={{__html: tableData.shop_title}}></span></td>

                        {/*order key*/}
                        <td>
                            {tableData.was_preorder ?
                                <span className="bg-blue-1 text-white px-1 w-max block rounded">پیش سفارش</span> : ''}

                            <Link to={`/orders/edit?id=${tableData._id}`} className="block py-1 text-purple-4">{tableData.order_key}</Link>

                            <div className="flex justify-center gap-4">
                                {tableData?.metadata?.extra_description && <span
                                    className="bg-gray-12 text-white rounded px-5 mx-auto w-max absolute top-[-13px] right-0 left-0">
                                      {tableData?.metadata?.extra_description}
                                  </span>}
                                {tableData.is_transference && (
                                    <button title="حواله ثبت شده">
                                        <FontAwesomeIcon className="text-[#1dd96c]" icon={faCheckCircle}/>
                                    </button>)}
                                {tableData?.metadata?.inventory_confirmation?.status === "submitted" && (
                                    <button title="تاییدیه انبار">
                                        <FontAwesomeIcon className="text-green-2" icon={faCheckToSlot}/>
                                    </button>)}
                                {tableData?.metadata?.accountant_confirmation?.status === "submitted" && (
                                    <button title="تاییدیه حسابدار">
                                        <FontAwesomeIcon className="text-[#1c8f20]" icon={faListCheck}/>
                                    </button>)}
                                {tableData.factor?.status === "submitted" && (
                                    <button title="تاییدیه فاکتور">
                                        <FontAwesomeIcon className="text-[#5e8f1c]" icon={faSquareCheck}/>
                                    </button>)}
                             </div>

                        </td>

                        <td>
                            <div className="relative">
                                  <span
                                      className="bg-blue-3 text-white rounded px-1 mx-auto w-max absolute top-[-17px] right-0 left-0"
                                      dangerouslySetInnerHTML={{__html: tableData.statusDate}}></span>
                                <span className={tableData.status === 'process' ? 'purple-tag' :
                                    tableData.status === 'pending' ? 'orange-light-3-tag' :
                                        tableData.status === 'approved' ? 'blue-light-2-tag' :
                                            tableData.status === 'shipment' ? 'purple-2-tag' :
                                                tableData.status === 'completed' || tableData.status === 'تکمیل پ.سفارش' ? 'green-tag' :
                                                    tableData.status === 'refunded' ? 'gray-tag' :
                                                        tableData.status === 'canceled' || tableData.status === 'لغو پ.سفارش' ? 'red-tag' :
                                                            tableData.status === 'failed' ? 'gray-2-tag' : 'orange-tag'}>
                            {orderStatusLabel(tableData.status)}
                        </span>
                            <span className="text-[12px]">{getDateAsReadableFormat(tableData?.metadata?.logico?.started_date , true)}</span>
                            </div>
                        </td>
                        <td><span>{getDateAsReadableFormat(tableData.transference.date,true)}</span></td>
                        <td>
                            <div>{ getDateAsReadableFormat(tableData.delivery_time?.start_date) }</div>
                            <div>{tableData?.delivery_time?.start_pick} تا {tableData?.delivery_time?.end_pick}</div>
                        </td>
                        <td className="text-right inline-table">
                            <button onClick={() => {showProducts(tableData)}} className={`btn-blue-light py-1 max-w-max px-1 mt-1 text-[11px] flex items-center gap-1`}>
                                {checkStock(tableData) && <FontAwesomeIcon className="text-red-2 ml-1" icon={faExclamationTriangle}/>}
                                <FontAwesomeIcon icon={faEye}/>
                            </button>

                        </td>
                        <td><span>{tableData.transference.description}</span></td>
                        <td>{tableData?.address?.coordinates?.state?.faname}</td>
                        <td>{tableData?.address?.coordinates?.city?.faname}</td>
                        <td className="relative">
                        {tableData?.metadata?.shipment_description && (
                                    <div
                                        onClick={() => setExpandedDescription(expandedDescription === tableData._id ? null : tableData._id)}
                                        className={`bg-gray-12 text-white rounded px-5 mx-auto w-max absolute top-[-2px] right-0 left-0 cursor-pointer ${
                                            expandedDescription === tableData._id ? '!w-[360px]' : ''
                                        }`}>
                                        {expandedDescription === tableData._id 
                                            ? tableData.metadata.shipment_description
                                            : truncateText(tableData.metadata.shipment_description)
                                        }
                                    </div>
                                )}
                            <span className="block">{tableData?.metadata?.driver?.name}</span>
                        </td>
                        <td>{tableData.address?.coordinates?.area?.faname}</td>
                        <td className="text-right max-w-[300px] inline-table"><span>
                        {tableData?.address?.address?.address ?? tableData?.address?.address}
                    </span></td>
                        <td>{ tableData.items.filter( (item) => item.item_type === "shipment" ).map( (item) => formatNumberWithDots(getDriverPrice(item)) ) }</td>
                        <td>{formatNumberWithDots(tableData.amounts.remaining)}</td>
                        <td>{formatNumberWithDots(tableData.amounts.shipment)}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal isOpen={showProductsModal} onClose={() => {setShowProductsModal(false)}}>
                <div className="page-title mb-3"><span className="inline-block bg-white text-14 font-bold pl-3">محصولات</span></div>
                <div className={`flex flex-col gap-3`}>
                    { products && products.map( (item) =>
                            <div className={`flex justify-between gap-2`}>
                                <span className={`text-red-2 order-1`}>(x{item.quantity} عدد)</span> 
                                <span className={`text-red-2 order-3`}>({item.stocks.iran} م.ا.ایران)</span> 
                                <div className={`order-2 text-[#333]`}>
                                    {(item.stocks.iran - item.quantity) < 0 && <FontAwesomeIcon className="text-red-2 ml-1" icon={faExclamationTriangle}/>}
                                    {item.title}
                                </div>
                            </div>
                    )}
                    { selectedOrder && selectedOrder.items.filter( (item) => item?.item_sub_type === "private_product" ).map( (item) =>
                            <div className={`flex justify-between gap-2`}>
                                <span className={`text-red-2 order-1`}>(x{item.quantity} عدد)</span> 
                                <span className={`text-red-2 order-3`}>(آیتم سفارشی)</span> 
                                <div className={`order-2 text-[#333]`}>
                                    {item.item_name}
                                </div>
                            </div>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default OrderTable