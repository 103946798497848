import { useEffect, useState, useMemo } from "react";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ApiRoute from "../../helper/api";

function SelectCoordinates2({
    defaultValue = {},
    defaultValueMultiple = false,
    setCoordinate = () => {},
    multiple = false,
    refresh = '1'
}) {
    const [selected, setSelected] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [provinces, setProvinces] = useState([]);

    // تبدیل داده‌ها به فرمت مناسب react-select
    const provinceOptions = useMemo(() => 
        provinces.map(province => ({
            value: province.uid,
            label: province.faname,
            data: province
        }))
    , [provinces]);

    const cityOptions = useMemo(() => 
        selected.flatMap(province => 
            province.cities?.map(city => ({
                value: city.uid,
                label: city.faname,
                data: city
            })) || []
        )
    , [selected]);

    const areaOptions = useMemo(() => 
        selectedCity.flatMap(city => 
            city.areas?.map(area => ({
                value: area.uid,
                label: area.faname,
                data: area
            })) || []
        )
    , [selectedCity]);

    // استایل‌های سفارشی
    const customStyles = {
        control: (base) => ({
            ...base,
            direction: 'rtl',
            textAlign: 'right',
            minHeight: '42px'
        }),
        menu: (base) => ({
            ...base,
            textAlign: 'right'
        })
    };

    // useEffect برای دریافت استان‌ها
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchProvinces();
            setProvinces(data);
        };
        fetchData();
    }, [refresh]);

    // useEffect جداگانه برای مدیریت defaultValue
    useEffect(() => {
        if (!provinces.length) return;
        
        if (defaultValueMultiple) {
            const states = provinces.filter(province => 
                defaultValue.state?.some(s => s.uid === province.uid)
            );
            
            const cities = states.flatMap(province => 
                province.cities?.filter(city => 
                    defaultValue.city?.some(c => c.uid === city.uid)
                ) || []
            );
            
            const areas = cities.flatMap(city => 
                city.areas?.filter(area => 
                    defaultValue.area?.some(a => a.uid === area.uid)
                ) || []
            );
            
            setSelected(states);
            setSelectedCity(cities);
            setSelectedArea(areas);
            updateCoordinates(states, cities, areas);
        } else if (Object.keys(defaultValue).length > 0) {
            const state = provinces.find(province => 
                province.uid === defaultValue.state?.uid
            );
            
            const city = state?.cities?.find(city => 
                city.uid === defaultValue.city?.uid
            );
            
            const area = city?.areas?.find(area => 
                area.uid === defaultValue.area?.uid
            );
            
            const stateArray = state ? [state] : [];
            const cityArray = city ? [city] : [];
            const areaArray = area ? [area] : [];

            setSelected(stateArray);
            setSelectedCity(cityArray);
            setSelectedArea(areaArray);
            updateCoordinates(stateArray, cityArray, areaArray);
        }
    }, [provinces, defaultValue, defaultValueMultiple]);

    const fetchProvinces = async () => {
        try {
            const res = await ApiRoute.coordinate.search.fn();
            return res.results.data;
        } catch (error) {
            console.error('Error fetching provinces:', error);
            return [];
        }
    };

    const handleProvinceChange = (selectedOptions) => {
        const selectedProvinces = multiple 
            ? selectedOptions?.map(option => option.data) || []
            : selectedOptions ? [selectedOptions.data] : [];
            
        setSelected(selectedProvinces);
        setSelectedCity([]);
        setSelectedArea([]);
        updateCoordinates(selectedProvinces, [], []);
    };

    const handleCityChange = (selectedOptions) => {
        const selectedCities = multiple 
            ? selectedOptions?.map(option => option.data) || []
            : selectedOptions ? [selectedOptions.data] : [];
            
        setSelectedCity(selectedCities);
        setSelectedArea([]);
        updateCoordinates(selected, selectedCities, []);
    };

    const handleAreaChange = (selectedOptions) => {
        const selectedAreas = multiple 
            ? selectedOptions?.map(option => option.data) || []
            : selectedOptions ? [selectedOptions.data] : [];
            
        setSelectedArea(selectedAreas);
        updateCoordinates(selected, selectedCity, selectedAreas);
    };

    const updateCoordinates = (states, cities, areas) => {
        const cleanStates = states.map(({ cities, ...state }) => state);
        const cleanCities = cities.map(({ areas, ...city }) => city);
        
        setCoordinate({
            state: cleanStates,
            city: cleanCities,
            area: areas
        });
    };

    return (
        <div className="flex flex-col gap-4">
            <Select
                styles={customStyles}
                menuPosition="fixed"
                options={provinceOptions}
                isMulti={multiple}
                placeholder="انتخاب استان"
                onChange={handleProvinceChange}
                isDisabled={!provinces.length}
                value={provinceOptions.filter(option => 
                    selected.some(s => s.uid === option.value)
                )}
            />

            <Select
                styles={customStyles}
                options={cityOptions}
                menuPosition="fixed"
                isMulti={multiple}
                placeholder="انتخاب شهر"
                onChange={handleCityChange}
                isDisabled={!selected.length}
                value={cityOptions.filter(option => 
                    selectedCity.some(c => c.uid === option.value)
                )}
            />

            <Select
                styles={customStyles}
                menuPosition="fixed"
                options={areaOptions}
                isMulti={multiple}
                placeholder="انتخاب منطقه"
                onChange={handleAreaChange}
                isDisabled={!selectedCity.length}
                value={areaOptions.filter(option => 
                    selectedArea.some(a => a.uid === option.value)
                )}
            />
        </div>
    );
}

export default SelectCoordinates2;