import React, {useEffect} from "react";
import SwitchToggle from "../Utilities/SwitchToggle";
import FileUpload from "../FileUpload";

export default function ShopMainEdit({shop}){

    useEffect(() => {
        if (!shop?.official_info || Object.keys(shop?.official_info).length === 0 || shop?.official_info?.length === 0){
            shop.official_info = {
                title: '',
                economic_code: '',
                register_number: '',
                serial_number: '',
            }
        }
    }, [shop]);

    return (

            <div
                className="text-xs border border-gray-7 rounded-lg p-4 mb-2 mt-5 items-center before-table-style before-w-4 before-purple">
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">تنظیمات فروشگاه</span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-9 gap-5 mt-5">
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        وضعیت
                        <SwitchToggle onSwitch={ (c) => {
                            shop.active = c
                        } } onOff={shop?.active ?? false}/>

                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        ارسال پیامک
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_sms = c
                        } } onOff={shop?.has_sms ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        پرداخت آنلاین
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_shaparak_payment_method = c
                        } } onOff={shop?.has_shaparak_payment_method ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        پیش فروش
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_preorder = c
                        } } onOff={shop?.has_preorder ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        پرداخت در محل
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_cod_payment_method = c
                        } } onOff={shop?.has_cod_payment_method ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        تایید حقوقی
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_official_confirmation = c
                        } } onOff={shop?.has_official_confirmation ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-2 pr-3 rounded">
                        تایید حسابداری
                        <SwitchToggle  onSwitch={ async (c) => {
                            shop.has_accountant_confirmation = c
                        } } onOff={shop?.has_accountant_confirmation ?? false}/>
                    </div>
                    <div className="border border-gray-7 flex items-center justify-between py-1 px-3 rounded">
                        درصد حمل و نقل
                        <input onKeyUp={ (e) => {
                            shop.shipping_coefficient_percentage = e.target.value
                        } } type="number" className="w-12 text-center bordered-input !border-gray-7 !p-2"
                               defaultValue={shop?.shipping_coefficient_percentage ?? 0}/>
                    </div>
                    <div className="lg:col-span-3">
                        {/*{shop?.logo?.length > 0 && <img src={shop.logo} className={`object-cover rounded mb-1 w-[50px] h-[50px]`}/>}*/}
                        {shop && <FileUpload defaultFile={shop?.logo && [
                            {
                                source: shop.logo,
                                options: {
                                    type: 'local',
                                },
                            },
                        ]} setCover={(v) => {
                            shop.logo = v
                        }}/>}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-6 gap-5 mt-5">
                    <label className="lg:col-span-3 !flex items-center">
                        <span className="ml-3">نام شخص حقیقی / حقوقی</span>
                        <input onKeyUp={ (e) => {
                            shop.official_info.title = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7"
                               defaultValue={shop?.official_info?.title}/>
                    </label>
                    <label className="!flex items-center">
                        شماره اقتصادی
                        <input onKeyUp={ (e) => {
                            shop.official_info.economic_code = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.official_info?.economic_code}/>
                    </label>
                    <label className="!flex items-center">
                        شماره ثبت / ملی
                        <input onKeyUp={ (e) => {
                            shop.official_info.register_number = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.official_info?.register_number}/>
                    </label>
                    <label className="!flex items-center">
                        شماره سریال
                        <input onKeyUp={ (e) => {
                            shop.official_info.serial_number = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.official_info?.serial_number}/>
                    </label>

                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-6 gap-5 mt-5">
                    <label className="lg:col-span-3 !flex items-center">
                        <span className="ml-3">آدرس فروشگاه</span>
                        <input onKeyUp={ (e) => {
                            shop.address = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7"
                               defaultValue={shop?.address}/>
                    </label>
                    <label className="!flex items-center">
                        کدپستی فروشگاه
                        <input onKeyUp={ (e) => {
                            shop.postcode = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.postcode}/>
                    </label>
                    <label className="!flex items-center">
                        شماره تماس
                        <input onKeyUp={ (e) => {
                            shop.phone = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.phone}/>
                    </label>
                    <label className="!flex items-center">
                        شماره موبایل
                        <input onKeyUp={ (e) => {
                            shop.cellphone = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.cellphone}/>
                    </label>

                    <label className="!flex col-span-full items-center">
                    اطلاعات حساب در پایین فاکتور
                        <textarea style={{minHeight:'100px'}} onInput={ (e) => {
                            shop.pre_invoice_footer_info = e.target.value
                        } } type="text" className="bordered-input flex-auto !border-gray-7 !mr-2"
                               defaultValue={shop?.pre_invoice_footer_info}/>
                    </label>

                </div>

            </div>

    )
}