import {useRef, useState} from "react";
import {hasAccess} from "../../helper/permissions";

export default function ProductFagTab({options , selectedTab ,setSelectedTab}){
    const myRef = useRef(null);
    const filters = [
        {
            title: 'همه',
            quantity: options?.all ?? 0,
            hasAccess:true,
            name: '',
            active: true
        }, {
            title: 'تایید شده',
            name: 'approved',
            quantity: options?.approved ?? 0,
            hasAccess:true,
            active: false
        }, {
            title: 'تایید نشده',
            name: 'unapproved',
            quantity: options?.unapproved ?? 0,
            hasAccess:true,
            active: false
        },
    ];

    function getFilters(){
        return filters
    }
    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
            <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto" ref={myRef}>
                {getFilters().filter( item => item.hasAccess ).map((item, id)=>(
                    <button
                        key={id}
                        onClick={ e => {
                            active(item , e)
                        } }
                        className={`text-xs w-max ml-4 whitespace-nowrap ${selectedTab === item.name ? ' text-purple-1 ':''}`}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </button>
                ))}
            </div>
        </>
    )
}