import {useState} from "react";

export default function IntegrateTaskFilterTab({options , selectedTab ,setSelectedTab}){
    const [active,setActive] = useState('all')
    const filters = [
        {
            title: 'در انتظار',
            quantity: options?.pending ?? 0,
            name: 'pending',
        },
        {
            title: 'ناموفق',
            quantity: options?.failed ?? 0,
            name: 'failed',
        },
        {
            title: 'موفق',
            quantity: options?.success ?? 0,
            name:'success',
        }
    ]

    function _active(item , el){
        setSelectedTab(item.name)
        setActive(item.name)
    }

    return(
        <>
            <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
                {filters.map((item, id)=>(
                    <button
                        onClick={ e => {
                            _active(item , e)
                        } }
                        className={`text-xs w-max ml-4 whitespace-nowrap ${ (item.name === active) && ' text-purple-1' }`} key={id}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </button>
                ))}
            </div>
        </>
    )
}