import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLocationPin} from "@fortawesome/free-solid-svg-icons";
import {getDateAsReadableFormat, getDateByFormat} from "../../../helper/general";

export default function OrderInfo({order, setUpdateState}){
    console.log(order?.factor)
    return (
        <div
            className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
            <div className="page-title mb-3">
                <span className="inline-block bg-white pl-3 text-sm">اطلاعات سفارش</span>
            </div>
            <div
                className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 lg:gap-5 text-xs mt-5">
                <div>
                    <span className="text-gray-2">تاریخ ثبت سفارش:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order.created_at , true)}</span>
                </div>
                <div className="xl:text-center">
                    <span className="text-gray-2">تاریخ تایید ویزیتور:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.status_date?.process , true)}</span>
                </div>
                <div className="xl:text-end">
                    <span className="text-gray-2">تاریخ تایید تامین کننده:</span>
                    {order?.metadata?.factor?.date}
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.factor?.date , true)}</span>
                </div>
                <div>
                    <span className="text-gray-2">تاریخ حواله:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.transference?.date , true)}</span>
                </div>
                <div className="xl:text-center">
                    <span className="text-gray-2">تاریخ تایید انبار:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.metadata?.inventory_confirmation?.date , true)}</span>
                </div>
                <div className="xl:text-end">
                    <span className="text-gray-2">تاریخ در حال ارسال:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.status_date?.shipment , true)}</span>
                </div>
                <div>
                    <span className="text-gray-2">تاریخ ارسال شده:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.metadata?.logico?.started_date , true)}</span>
                </div>
                <div className="xl:text-center">
                    <span className="text-gray-2">تاریخ تحویل شده:</span>
                    <span className="text-gray-5 mr-1">{getDateAsReadableFormat(order?.status_date?.completed , true)}</span>
                </div>
                <div className="xl:text-end">
                    <span className="text-gray-2">تاریخ زمان ارسال:</span>
                    <span className="text-gray-5 mr-1">
                        {getDateByFormat(order?.delivery_time?.start_date , 'dddd D MMM YYYY')}
                        <i className={`mr-2`}>( {order?.delivery_time?.start_pick} تا {order?.delivery_time?.end_pick} )</i>
                    </span>
                </div>
            </div>
        </div>
    )
}