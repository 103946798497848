import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faEdit,
    faRectangleList,
    faCartShopping,
    faLineChart,
    faFileInvoiceDollar,
    faBars,
    faSort,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import {faCircleCheck} from '@fortawesome/free-regular-svg-icons'
import Select from "../Utilities/Select";
import React, {useState} from "react";
import Modal from "../Utilities/Modal";
import CustomMultiSelect from "../Utilities/CustomMultiSelect";
import Accordion from "../Utilities/Accordion";
import Toman from "../Utilities/Toman";
import PriceChart from "../Utilities/PriceChart";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../helper/general";
import ApiRoute from "../../helper/api";
import {toast} from "react-toastify";
import EditModel from "../Inventory/Modal/EditModal";
import PriceChartModel from "../Inventory/Modal/PriceChartModal";
import {Link} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import PriceField from "../Common/PriceField";
import {hasAccess, HasAccessComponent} from "../../helper/permissions";
import PriceExpiredModel from '../Inventory/Modal/PriceExpiredModel';

function InventoryTable(props) {
    const MySwal = withReactContent(Swal)

    const tableTitles = [
        { title: 'شناسه'},
        {
            title: 'ش.آسان',
            sort:true,
            name:"asan_id"
        },
        {
            title: 'تایید',
            sort:true,
            name:"is_reviewable"
        },
        {
            title: 'ا.امروز',
            sort:true,
            name:"has_today_shipment"
        },
        { title: 'ارسال'},
        {
            title: 'پ.فروش',
            sort: true,
            name:"has_presell"
        },
        {
            title: 'م.پ',
            sort: true,
            name:"stocks_presell"
        },
        { title: 'رزرو',
          sort: true,
          name:"stocks_reservation"
        },
        {
            title: 'موجودی',
            sort: true,
            name:"stocks_web_site"
        },
        {
            title: 'م کل',
            sort:true,
            name:"total_stocks"
        },
        {
            title: 'عنوان',
            sort: true,
            name:"title"
        },
        { title: 'قیمت پیش', isPrice: true},
        { title: 'قیمت مرجع', isPrice: true},
        {
            title: '%.ق',
            sort: true,
            name:"price_percent"
        },
        {
            title: 'قیمت',
            isPrice: true,
            sort: true,
            name:"price"
        },
        {
            title: 'ویژه عادی',
            isPrice: true,
            sort: true,
            name:"regular_price"
        },
        {
            title: '%ق.و',
            sort: true,
            name:"regular_price_percent"
        },
        {
            title: 'قیمت ویژه',
            isPrice: true,
            sort: true,
            name:"sale_price"
        },
        {
            title: '%ف.ق',
            sort: true,
            name:"sale_price_percent"
        },
    ]
    const [isModalOpen, setIsModalOpen] = useState(null);
    const [modalData, setModalData] = useState(null);
    const openModal = (name,extraData = null) => {
        setModalData(extraData)
        setIsModalOpen(name)
    }
    const closeModal = () => setIsModalOpen(null)

    const [selectedId, setSelectedId] = useState([]);
    const [selectedSendMethod, setSelectedSendMethod] = useState([]);
    
    const [selectedPriceHistory, setSelectedPriceHistory] = useState(null);

    // new

    const [selectedItem , setSelectedItem] = useState([]);

    function changeSelectedItem(value , name , tableData){
        if (!selectedItem[tableData._id]){
            selectedItem[tableData._id] = [];
        }
        selectedItem[tableData._id][name] = value;
        setSelectedItem(selectedItem)

    }

    async function handleUpdate(id){

        const res = await ApiRoute.product.inventory.update.fn(id, {
            model:"\\App\\ProductManager\\Model\\ProductFull",
            fields: { ...selectedItem[id] }
        })

        //toast.success("بروزرسانی انجام شد")
    }
    // new

    const handleMultiSelectIds = (selectedId) => {
        setSelectedId(selectedId)
    }
    const handleMultiSelectSendMethods = (selectedSendMethod) => {
        setSelectedSendMethod(selectedSendMethod)
    }

    async function handleShowProductRule(product) {
        const res = await ApiRoute.price.rule.getProductRule.fn(product._id)
        if ( res?.status_code !== 200 ){
            return toast.error("خطای غیر منتظره")
        }
        let html = `<ul style="font-weight: bold;font-size: 20px;">`
        html += `<li class="flex mb-1 justify-between item-center gap-3"><div>سقف:</div><div>${res.results.ceil}</div></li>`
        html += `<li class="flex mb-1 justify-between item-center gap-3"><div>کف:</div><div>${res.results.floor}</div></li>`
        html += `<li class="flex mb-1 justify-between item-center gap-3"><div>مرز حداکثر:</div><div>${res.results.max}</div></li>`
        html += `<li class="flex mb-1 justify-between item-center gap-3"><div>مرز حداقل:</div><div>${res.results.min}</div></li>`
        html += `<li class="flex justify-between item-center gap-3"><div>پیش فروش:</div><div>${res.results.presell}</div></li>`
        html += `</ul>`
        MySwal.fire({
            title: `<p style="font-size: 20px;">قوانین ${product.title}</p>`,
            html: html,
            confirmButtonText: "متوجه شدم",
            cancelButtonText: `دست نگه دار`,
            showCancelButton: false,
        })
    }

    async function handlePriceHistory(product) {
        const res = await ApiRoute.product.priceHistory.fn(product._id)
        setSelectedPriceHistory(res.results)
        setIsModalOpen('changePrice')
    }

    return (
        <>
            <table className="table-auto w-full block mt-4">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {tableTitles.map((item, id) => (
                        <td onClick={ () => {
                            if (item.sort !== true) return;
                            let dir = localStorage.getItem("sort_dir") ?? "desc";
                            if ( dir === "desc" ){
                                localStorage.setItem("sort_dir", "asc")
                            }else{
                                localStorage.setItem("sort_dir", "desc")
                            }
                            props.onSort(item.name , dir)
                        } } className={ (item.sort && 'cursor-pointer text-purple-1') +` text-13 relative text-gray-6 pb-2`} key={id}>
                            {item.title}
                            {item.sort && <FontAwesomeIcon className={`mr-2`} icon={faSort}/>}
                        </td>
                    ))}
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {props.tableData?.map(tableData => (
                        <tr className={`text-xs py-2`} key={tableData?.id}>
                            <td className="!relative">
                                <FontAwesomeIcon icon={faCircleCheck} className={parseInt(tableData?.product_id) > 0 ? 'text-green-1 text-sm' : 'text-red-1 text-sm'}/>
                                {tableData.price_expired == 1 ? <FontAwesomeIcon onClick={ () => { openModal('priceExpired',tableData) } } title='قیمت منقضی شده' icon={faCircleExclamation} className="text-orange-2 cursor-pointer mt-1 text-sm"/> : ''}
                            </td>
                            <td>
                                <input disabled={!hasAccess('asan_id inventory')} onChange={ (e) => {changeSelectedItem(e.target.value,'asan_id',tableData);} }
                                       type={`number`}
                                       defaultValue={tableData?.asan_id}
                                       className="border w-[70px] border-gray-7 rounded p-1"/>
                            </td>
                            <td>
                                <Select
                                    disabled={!hasAccess('hold inventory')}
                                    onChange={ (v) => {
                                        changeSelectedItem(v,'is_reviewable',tableData)
                                    } }
                                    initialValue={tableData?.is_reviewable ? "true" : "false"}/>
                            </td>
                            <td>
                                <Select
                                    disabled={!hasAccess('shipment_day inventory')}
                                    onChange={ (v) => {
                                        changeSelectedItem(v,'has_today_shipment',tableData)
                                    } }
                                    initialValue={tableData?.has_today_shipment ? "true" : "false"}/>
                            </td>
                            <td className="min-w-[100px]">{tableData?.send}</td>
                            <td>
                                <Select onChange={ (v) => {
                                    changeSelectedItem(v,'has_presell',tableData)
                                } } initialValue={tableData?.has_presell ? "true" : "false"}/>
                            </td>
                            {/*presell stock*/}
                            <td><input disabled={!hasAccess(`presell_stock inventory`)} onChange={ (e) => {changeSelectedItem(e.target.value,'stocks_presell',tableData);} }
                                       type={`number`}
                                       defaultValue={tableData?.stocks.presell}
                                       className="border w-[50px] border-gray-7 rounded p-1"/></td>
                            {/*reservation stock*/}
                            <td><span className="bg-gray-7 rounded p-1">{tableData?.stocks?.reservation ?? 0}</span></td>
                            {/*website stock*/}
                            <td><input
                                disabled={!hasAccess('stock inventory')}
                                onChange={ (e) => {changeSelectedItem(e.target.value,'stocks_web_site',tableData);} }
                                type={`number`}
                                defaultValue={tableData?.stocks.web_site} className="border w-[50px] border-gray-7 rounded p-1"/></td>
                            {/*total (asan) stock*/}
                            <td>
                                <div className={`grid grid-cols-2 gap-1 w-[150px]`}>
                                    <span className="bg-gray-7 rounded p-1">ایران  {tableData?.stocks?.iran ?? 0}</span>
                                    <span className="bg-gray-7 rounded p-1">کوروش  {tableData?.stocks?.kourosh ?? 0}</span>
                                    <span className="bg-gray-7 rounded p-1">فرانچایز  {tableData?.stocks?.kourosh ?? 0}</span>
                                    <span className="bg-gray-7 rounded p-1">شرکت  {tableData?.stocks?.company ?? 0}</span>
                                </div>
                            </td>
                            {/*title*/}
                            <td className="min-w-[220px]">
                                <Link target='_blank' to={`/products/edit?product_id=${tableData._id}`}>{tableData?.title}</Link>
                                {tableData.status === "draft" ? ( <i>(پیشنویس)</i> ) : ''}
                                {tableData.in_campaign === true ? (<i className="text-[#004D7F] mx-1 font-bold">(کمپین)</i>) : ''}
                                <span className={tableData.stocks.web_site < 1 ? `text-pink-1` : 'text-green-1'}><b> ({tableData.stocks.web_site > 0 ? 'موجود' : 'ناموجود'}) </b></span>
                            </td>
                            {/*price*/}
                            <td>
                                {/*{tableData?.price_p?.percent > 0 ? (
                                    <div className="w-max mx-auto relative">
                                        <span className="bg-gray-7 rounded p-1">{tableData?.price_p?.price}</span>
                                        <span
                                            className="block text-right text-red-1 mt-1 absolute right-0 bottom-[-20px]">{tableData?.price_p?.percent}%</span>
                                    </div>
                                ) : (
                                    <div className="w-max mx-auto relative">
                                        <span className="border border-green-1 rounded p-1">{tableData?.price_p?.price}</span>
                                        <span
                                            className="block text-right text-green-1 mt-1 absolute right-0 bottom-[-20px]">{tableData?.price_p?.percent}%</span>
                                    </div>
                                )

                                }*/}

                                <div className="w-max mx-auto relative">
                                    <PriceField disabled={!hasAccess(`presell_price inventory`)} className={`!w-[70px] !text-[11px]`} onChange={ (v) => {
                                        changeSelectedItem(v,'prices_presell',tableData);
                                    } } defaultValue={tableData?.prices?.presell}/>
                                </div>

                            </td>
                            {/*reference price*/}
                            <td>
                                <div className="w-max mx-auto flex flex-col relative">
                                    <PriceField disabled={!hasAccess('ref_price inventory')} className={`!w-[70px] !text-[11px]`} onChange={ (v) => {
                                        changeSelectedItem(v,'prices_reference',tableData);
                                    } } defaultValue={tableData?.prices?.reference}/>
                                    <button onClick={() => {handleShowProductRule(tableData)}} className={`btn-blue-light py-1 max-w-max px-1 mt-1 text-[11px] flex items-center gap-1`}>
                                        <span>قوانین</span>
                                        <FontAwesomeIcon icon={faEye}/>
                                    </button>
                                </div>

                            </td>
                            {/* price percent */}
                            <td className={ (tableData?.metadata?.prices?.price?.percent > 0) ? `text-green-1` : 'text-red-1'} dir={`ltr`}>{tableData?.metadata?.prices?.price?.percent ?? 0}%</td>
                            {/* price */}
                            <td>
                                <div className="w-max mx-auto relative">
                                    {/*<FontAwesomeIcon className="text-yellow-1 text-xl absolute right-0 top-[-22px]" icon={faCircleExclamation}/>*/}
                                    <PriceField disabled={!hasAccess('price inventory') || tableData?.in_campaign === true} className={`!w-[70px] !text-[11px]`} onChange={ (v) => {
                                        changeSelectedItem(v,'prices_price',tableData);
                                    } } defaultValue={tableData?.prices?.price ?? 0}/>
                                </div>
                            </td>
                            {/* price regular */}
                            <td>
                                <PriceField disabled={!hasAccess('regular_price inventory') || tableData?.in_campaign === true} className={`!w-[70px] !text-[11px]`} onChange={ (v) => {
                                    changeSelectedItem(v,'prices_regular',tableData);
                                } } defaultValue={tableData?.prices?.regular ?? 0}/>
                            </td>
                            {/* price regular percent */}
                            <td className={ (tableData?.metadata?.prices?.regular?.percent > 0) ? `text-green-1` : 'text-red-1'} dir={`ltr`}>{tableData?.metadata?.prices?.regular?.percent ?? 0}%</td>
                            {/* price sale */}
                            <td>
                                <PriceField 
                                    disabled={!hasAccess('sale_price inventory') || tableData?.in_campaign === true} 
                                    className={`!w-[70px] !text-[11px]`} 
                                    onChange={(v) => {
                                        changeSelectedItem(v,'prices_sale',tableData);
                                    }} 
                                    defaultValue={tableData?.prices?.sale ?? 0}
                                />
                            </td>
                            {/* price percent sale */}
                            <td className={ (tableData?.metadata?.prices?.sale?.percent > 0) ? `text-green-1` : 'text-red-1'} dir={`ltr`}>{tableData?.metadata?.prices?.sale?.percent ?? 0}%</td>

                            <td>
                                <div className="flex gap-1">

                                    <button onClick={ async () => { await handleUpdate(tableData._id) }} title="بروزرسانی"><FontAwesomeIcon
                                        className="bg-green-1 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                    <button title="اطلاعات اضافی" onClick={() => {
                                        openModal('moreDetail',tableData)
                                    }}><FontAwesomeIcon
                                        className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faRectangleList}/>
                                    </button>

                                    <HasAccessComponent permission={`price_chart inventory`} children={<button title="نمودار تغییرات قیمت" onClick={() => openModal('priceChart',tableData)}>
                                        <FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded"
                                            icon={faLineChart}/></button>}/>

                                    <button title="10 تغییر اخیر قیمت ها" onClick={() => handlePriceHistory(tableData)}>
                                        <FontAwesomeIcon
                                            className="bg-orange-1 text-white p-1 w-4 h-4 rounded"
                                            icon={faFileInvoiceDollar}/></button>

                                    <Link to={`/orders?product_id=${tableData.id}&product_title=${tableData.title}`} title="سفارشات"><FontAwesomeIcon
                                        className="bg-yellow-1 text-white p-1 w-4 h-4 rounded" icon={faCartShopping}/></Link>
                                    <Link to={`/orders?product_id=${tableData._id}`} title="پیش سفارشات"><FontAwesomeIcon
                                        className="bg-purple-4 text-white p-1 w-4 h-4 rounded" icon={faCartShopping}/></Link>

                                    <HasAccessComponent permission={`log inventory`} children={<button onClick={ () => {
                                        alert('لاگ ها بهینه شده و در لانچ نهایی فعال میشود')
                                    } }><FontAwesomeIcon
                                        className="bg-gray-5 text-white p-1 w-4 h-4 rounded" icon={faBars}/></button>}/>

                                </div>
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>

            { (isModalOpen && isModalOpen === 'moreDetail') && <EditModel product={modalData} closeModal={ () => {
                closeModal()
            } }/>}
            { (isModalOpen && isModalOpen === 'priceChart') && <PriceChartModel product={modalData} closeModal={ () => {
                closeModal()
            } }/>}

            { (isModalOpen && isModalOpen === 'priceExpired') && <PriceExpiredModel onSuccess={()=>{
                props.setUpdateData(Date.now())
            }} product={modalData} closeModal={ () => {
                closeModal()
            } }/>}

            <Modal isOpen={isModalOpen && isModalOpen === 'changePrice'} onClose={closeModal}
                   modalWidth="2xl:w-1/4 h-auto scrollbar-style dir-rtl">
                <div className="page-title z-1 mb-3 text-13"><span className="inline-block bg-white pl-3 z-2 relative">10 تغییر اخیر قیمت ها</span>
                </div>
                <div className="border border-gray-7 rounded-md p-3 text-xs">

          
                        <Accordion arrowIcon={true} customClsses="shadow-style px-4">

                        {selectedPriceHistory && Object.entries(selectedPriceHistory).map((item, index) => (
                        <div>
                        <div className="flex p-3">
                            <div>
                                <span className="text-gray-12 ml-1">تاریخ و ساعت:</span>
                                <span className="text-gray-5">{getDateAsReadableFormat(item[0],true)}</span>
                            </div>
                        </div>
                        <div className="pb-3">
                            <table className="table-auto rounded-md w-full box-shadow-bottom">
                                <thead>
                                <tr className="">
                                    <td className="text-right px-4 py-2 w-1/3 bg-gray-7 rounded-tr-md">نوع</td>
                                    <td className="text-center px-4 py-2 w-1/3 bg-gray-7">توسط</td>
                                    <td className="text-left px-4 py-2 w-1/3 bg-gray-7 rounded-tl-md">
                                        <div className="flex items-center justify-end">
                                            قیمت
                                            <Toman/>
                                        </div>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="shadow-none">
                                    <td className="text-right w-1/3 border-b border-b-gray-7">قیمت اصلی</td>
                                    <td className="text-center w-1/3 border-b border-b-gray-7">{item[1].employee_name}</td>
                                    <td className="text-left w-1/3 border-b border-b-gray-7">{item[1].price}</td>
                                </tr>
                                <tr className="shadow-none border-b border-b-gray-7">
                                    <td className="text-right w-1/3 border-b border-b-gray-7">قیمت ویژه عادی</td>
                                    <td className="text-center w-1/3 border-b border-b-gray-7">{item[1].employee_name ?? '-'}</td>
                                    <td className="text-left w-1/3 border-b border-b-gray-7">{item[1].regular ?? '-'}</td>
                                </tr>
                                <tr className="shadow-none border-b border-b-gray-7">
                                    <td className="text-right w-1/3 border-b border-b-gray-7">قیمت ویژه فوری</td>
                                    <td className="text-center w-1/3 border-b border-b-gray-7">{item[1].employee_name ?? '-'}</td>
                                    <td className="text-left w-1/3 border-b border-b-gray-7">{item[1].sale}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    ))}

                            
                       
                        
                       
                    </Accordion>
                </div>
            </Modal>


        </>
    )
}

export default InventoryTable