import { useState } from "react";

export default function AlertStockFilterBox({ setFilterQuery }) {
    const [filter, setFilter] = useState({
        status: "unread" 
    });

    const handleFilterChange = (name, value) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: value
        }));
    };

    const applyFilters = () => {
        setFilterQuery(filter);
    };

    return (
        <div>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="flex flex-wrap gap-3 text-xs">
                        <div>
                            <label>
                                <span className="block">وضعیت</span>
                                <select 
                                    value={filter.status}
                                    onChange={(e) => handleFilterChange("status", e.target.value)}
                                    className="bordered-input-7 bg-gray-1 w-full mt-1"
                                >   
                                    <option value="">همه</option>
                                    <option value="unread">خوانده نشده</option>
                                    <option value="read">خوانده شده</option>
                                    <option value="waiting">در انتظار</option>
                                </select>
                            </label>
                        </div>

                        <div className="flex items-end">
                            <button 
                                onClick={applyFilters}
                                className="btn-orange w-full py-2"
                            >
                                اعمال فیلتر
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}