import AccessRestricted from "../components/AccessRestricted";

export function hasAccess(permission,__permissions = []){
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    if( r?.employee?.role?.name === "super-admin" ){ return true; }
    let found = false;
    r.employee.permissions.map( _permission => {
        if (_permission.name === permission){
            return found = true;
        }
        __permissions.map(j => {
            if (j === _permission.name){
                return found = true;
            }
        })
    } )
    return found;
}

export function HasAccess({permission,children}){
    if (permission === true){
        return (children)
    }
    if (!hasAccess(permission)){
        return (<AccessRestricted/>)
    }
    return (children)
}

export function HasAccessComponent({permission,showIf = true,permissions = [],children}){
    if (permission === true){
        return (children)
    }
    if (!hasAccess(permission,permissions)){
        return ''
    }
    if (showIf !== true){return ''}
    return (children)
}