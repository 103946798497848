import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import ShopSelect from "../../components/Shop/ShopSelect";

export default function TransportSetting() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        title: '',
        asan_id:'',
        active:false
    })

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.shipment.transport.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function handleUpdate(item){
        await ApiRoute.shipment.transport.update.fn(item._id, item)
    }

    async function handleCreate(){
        await ApiRoute.shipment.transport.create.fn(newData)
        setUpdateData(Date.now().toString())
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">تنظیمات باربری</span>
                </div>
                <div className="text-xs text-purple-1 mt-6 mb-3 px-2">اضافه کردن باربری</div>
                <div className="main-content mx-auto p-5">
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 form grid-form gap-5 px-3">
                        <div><label>
                            نام
                            <input className="w-full" onKeyUp={ (e) => {
                                newData.title = e.target.value;
                            } } type="text"/>
                        </label></div>
                        <div
                            className="sm:col-span-2 lg:col-span-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-end justify-between gap-5">

                            <div className="sm:col-start-2 xl:col-start-3 text-left">
                                <button onClick={handleCreate} className="btn-purple w-32 xl:w-full py-2">افزودن</button>
                            </div>

                        </div>
                    </div>

                    <div className="text-xs text-purple-1 mt-8 mb-3 px-2">لیست باربری ها</div>
                    <div className="border border-blue-1 p-3 rounded-md">
                        {data && <table className="table-borderd-tr table-auto table-td-150 w-full mt-2 mx-auto block xl:table">
                            <thead>
                            <tr className="text-xs">
                                <td>نام</td>
                                <td>فروشگاه های مستثنی</td>
                                <td className="w-[13%]">وضعیت</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>

                                {tableData.map( (item,index) => <tr key={item._id}
                                    className="form grid-form text-xs border border-gray-7 rounded-md p-1">
                                    <td className="pr-1 pl-5"><input onKeyUp={ (e) => {
                                        item.title = e.target.value;
                                    } } className="w-full !mt-0" type="text" defaultValue={item.title}/></td>
                                    <td className="pl-5">
                                        <ShopSelect multiple={true} customDefaultValue={true} defaultValue={item.exclude_shop_list} assignItem={ (s) => {
                                            let arr = []
                                            s.map( (a) => {
                                                arr.push(a._id)
                                            } )
                                            item.exclude_shop = arr
                                        } }/>
                                    </td>
                                    <td className="pl-5">
                                        <SwitchToggle onSwitch={ async (c) => {
                                            item.active = c
                                        } } onOff={item?.active ?? false}/>
                                    </td>
                                    <td className="flex justify-center items-center pt-2">

                                        <button className="mx-2 leading-3 " onClick={ async () => await handleUpdate(item) } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                        <button className="mx-2" type={`button`} onClick={ async () => {
                                            await ApiRoute.shipment.transport.delete.fn(item._id)
                                            setUpdateData(Date.now().toString())
                                        } } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                    </td>
                                </tr> )}

                            </tbody>
                        </table>}
                    </div>
                    { data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}