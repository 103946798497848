import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiRoute, { shopId } from "../../helper/api";
import { getDateAsReadableFormat } from "../../helper/general";
import { faCheck, faCheckDouble, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/Utilities/Modal";
import SearchBox from "../../components/SearchBox";

export default function ViewForms() {
  const navigate = useNavigate();
  const [readState, setReadState] = useState('unread')
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [updateData, setUpdateData] = useState("0");
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState({});
  const [searchQuery, setSearchQuery] = useState({
    term: "",
    by: "title",
  });
  const [filterQuery, setFilterQuery] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const formId = params.get("id");


  //fetch data from server and update data /| based on updateData state
  useEffect(() => {
    (async () => {
      const res = await ApiRoute.form.inbox.index.fn(formId, filterQuery)
      setData(res);
    })();
  }, [updateData]);

  const paginationData = data?.results;
  const tableData = data?.results?.data ?? [];

  // watch pageNumber and update updateData state
  useEffect(() => {
    setUpdateData(Date.now().toString());
  }, [pageNumber]);
  // watch searchQuery and update updateData state
  useEffect(() => {
    filterQuery.search_options = searchOptions()
    filterQuery.quick_search = searchQuery
    setUpdateData(Date.now().toString());
  }, [searchQuery]);

  useEffect(() => {
    filterQuery.read_state = readState
    setUpdateData(Date.now().toString());
  }, [readState]);

  const tableTitles = [
    "عنوان فیلد",
    "مقدار فیلد",
    "تاریخ ثبت",
  ];

  const searchOptions = () => {
    let options = []
    tableData.length > 0 && tableData[0].data.forEach(item => {
      options.push({
        label: item.label,
        value: item.name
      })
    })

    if (tableData.length > 0 && tableData.some(item => item.product?.title)) {
      options.push({
        label: 'عنوان محصول',
        value: 'product_title'
      })
    }

    

    return options
  }

  return (
    <Layout>
      <div>
        <div className="page-title mb-3">
          <span className="inline-block bg-white pl-3">فرم ها - {data?.meta_data?.title}</span>
        </div>

        {tableData.length > 0 && <SearchBox 
        SearchByOptions={searchOptions()} 
        SearchByDefault={tableData[0].data.name} 
        SetSearchQuery={setSearchQuery} 
        SearchIn={true}/>}

      <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
                 <button
                    onClick={ e => {setReadState('unread')} }
                    className={`text-xs w-max ml-4 whitespace-nowrap${readState === 'unread' ? ' text-purple-1' : ''}`}>خوانده نشده&nbsp;({data?.meta_data?.unread ?? 0})
                </button>
                <button
                    onClick={ e => {setReadState('read')} }
                    className={`text-xs w-max ml-4 whitespace-nowrap${readState === 'read' ? ' text-purple-1' : ''}`}>خوانده شده&nbsp;({data?.meta_data?.read ?? 0})
                </button>
        </div>

        <div className="main-content p-5 mt-3">
          <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
              <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                  <td className="text-13 text-gray-6 pb-2" key={id}>
                    {item}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.map((tableData, id) => (
                <tr className={`text-xs py-3`} key={id}>
                  <td>{tableData?.product?.title ? 'محصول' : tableData.data[0].label}</td>
                  <td>{tableData?.product?.title ? tableData.product.title : tableData.data[0].value}</td>
                  <td>{getDateAsReadableFormat(tableData.created_at, true)}</td>
                  <td className="flex gap-2 items-center text-[15px]">
                    <button onClick={() => {
                      setIsOpen(true)
                      setSelectedData(tableData)
                    }} className={`text-gray-6`}>
                      <FontAwesomeIcon icon={faEye}/>
                    </button>
                    <button onClick={ async () => {
                      await ApiRoute.form.inbox.read.fn(tableData._id)
                      setUpdateData(Date.now().toString())
                    }} className={`text-green-1`}>
                      {tableData.is_read ? <FontAwesomeIcon icon={faCheckDouble}/> : <FontAwesomeIcon icon={faCheck}/>}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isOpen &&  <Modal isOpen={isOpen} onClose={() => {
        setIsOpen(false)
      }}>
        <div>
  
          <div className="flex text-[13px] flex-col gap-3">
            {selectedData?.data.map((item, id) => (
              <div className="flex gap-2 items-center" key={id}>
                <span className="bg-gray-9 px-2 py-1 rounded-md text-white">{item.label}</span>
                <span><b>{item.value}</b></span>
              </div>
            ))}
          </div>
        </div>
      </Modal>}

    </Layout>
  );
}
