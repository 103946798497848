import {orderStatusLabel, orderStatusList} from "../../../../helper/general";
import {useEffect, useState} from "react";
import ApiRoute from "../../../../helper/api";
import OrderShipmentStatusModal from "../../Modal/Edit/OrderShipmentStatusModal";
import {toast} from "react-toastify";
import OrderDefinedReason from "../OrderDefinedReason";
import Modal from "../../../Utilities/Modal";
import {hasAccess} from "../../../../helper/permissions";

export default function OrderStatus({order, setUpdateState}){
    const [status, setStatus] = useState(order.status);
    const [modal, setModal] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageChecked, setMessageChecked] = useState(true);
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(false);


    function checkPreOrderStatusAvailable(status){
        const remaining = order.amounts.remaining;

        let avb_status = [];

        if (status === 'waiting') {
            avb_status = ['pending', 'canceled'];
            if ( remaining === 0 ){
                avb_status.push('approved')
            }
        }
        if (status === 'pending'){
            avb_status = ['canceled','failed'];
            if( order.payment_method.name === 'bank_deposit' ){
                avb_status = ['canceled', 'failed'];
            }
            if ( remaining === 0 ){
                avb_status.push('approved');
            }
        }
        if (status === 'approved'){
            avb_status.push('canceled')
            //$avb_status[] = 'failed';
            if ( remaining === 0 ){
                avb_status.push('completed');
            }
        }
        if (status === 'completed'){
            avb_status.push('canceled');
        }
        if (status === 'failed'){
            avb_status.push('canceled')
            avb_status.push('pending')
        }

        return avb_status;
    }

    function checkStatusAvailable(status){

        if (order.is_preorder){
            return checkPreOrderStatusAvailable(status);
        }

        const status_list = orderStatusList();
        let avb_status = []
        if (status === 'approved') {
            avb_status = ['failed', 'process'];
        }
        if (status === 'process') {
            if( !order.is_transference ) {
                avb_status = [];
                //if( hasAccess('order_change_to_canceled_after_transference_access') ){
                    avb_status = ['canceled'];
                //}
            }else{
                if(  order.is_transference ){
                    avb_status = [];
                    //if(Auth::user()->hasPermission('order_change_to_shipment_access')){
                    avb_status.push('shipment');
                    //}
                    /*$per = 'change_status_from_process_to_refunded_transference order';
                    $per_cancelded = 'change_status_from_process_to_canceled_transference order';*/
/*                    if(  auth()->user()->can($per) && auth()->user()->hasDirectPermission($per)  ){
                    }
                    if( ( auth()->user()->can($per_cancelded) && auth()->user()->hasDirectPermission($per_cancelded)) || \auth()->user()->hasRole('super-admin')  ){
                        avb_status.push('canceled');
                    }*/

                    if( hasAccess('order_change_to_canceled_after_transference_access') ){
                        avb_status.push('canceled');
                        avb_status.push('refunded');
                    }


                }else{
                    avb_status = [];
                }

            }

        }

        if (status === 'completed') {
            avb_status = ['refunded'];
        }
        if (status === 'waiting') {
            avb_status = ['pending'];
            if( order.payment_method.name === 'bank_deposit' ){
                avb_status = [];
                avb_status.push('pending');
                //if ( Auth::user()->can('change_status_to_canceled_bank_deposit') ){
                    avb_status.push('canceled');
                //}
            }

            if( order.is_official && order?.metadata?.official_confirmation?.status !== "submitted" ){
                avb_status = ['canceled'];
            }

        }
        if (status === 'pending') {
            avb_status = ['canceled', 'failed', 'approved'];
            if( order.payment_method.name === 'bank_deposit' ){
                avb_status =[];
                avb_status.push('failed');
                //if ( Auth::user()->can('change_status_to_canceled_bank_deposit') ){
                    avb_status.push('canceled');
                //}
            }
        }
        if (status === 'shipment') {
            avb_status = ['refunded'];
            //if ( Auth::user()->can('change_status_from_shipment_to_process',$order) ){
                avb_status.push('process');
            //}
            //if ( Auth::user()->can('change_status_from_shipment_to_completed',$order) ){
                avb_status.push('completed');
            //}
        }
        if (status === 'failed') {
            avb_status = ['waiting','pending'];
            if( order.payment_method.name === 'bank_deposit' ){
                avb_status = ['pending'];
            }
        }

        if( !order.is_transference ) {
            //if( Auth::user()->hasPermission('order_change_to_canceled_after_transference_access') ){
            avb_status.push('canceled');
            //}
        }

        return avb_status;
    }

    function getStatusList(){
        let arr = []
        arr.push(order.status)
        arr.push(...checkStatusAvailable(order.status))
        arr = [... new Set(arr)];
        return arr
    }

    async function handleUpdate(){
        let needToTransferenceStatus = [
            'change_status_from_process_to_shipment order'
        ]

        let notAllowed = false
        let current_status = order.status;
        let cond_status = 'change_status_from_'+current_status+'_to_'+status;

        if (needToTransferenceStatus[cond_status+" order"]){
            if ( order.is_transference && !hasAccess((cond_status)+" order") ){
                //notAllowed = true
            }
        }else{
            if (!hasAccess(cond_status+" order")){
                //notAllowed = true
            }
        }

        if (notAllowed){
            return toast.error("دسترسی انجام این عمل را ندارید")
        }

        if(status === "process" && !order?.delivery_time?.start_date){
            toast.error("زمان ارسال خالی است")
            return;
        }

        if ( order.status === "process" && status === "shipment" && !needToBeConfirmed() ){
            toast.error("جهت تغییر وضعیت باید سفارش به تایید تامین کننده، انبار و ثبت حواله برسد")
            return;
        }

        if ( (order?.shop_info?.has_accountant_confirmation ?? true) !== false && order.status === "process" && status === "shipment" && needToBeConfirmedAccountant() ){
            toast.error("جهت تغییر وضعیت باید سفارش ابتدا به تایید حسابدار برسد")
            return;
        }

        if(status === "shipment" && !order?.delivery_time?.start_date){
            toast.error("زمان ارسال سفارش را وارد کنید")
            return;
        }

        if(status === "canceled"){
            setModal("canceled")
            return;
        }

        if(status === "shipment"){
            setModal("shipment")
            return;
        }

        await sentToServer()
    }

    async function sentToServer(_metadata = null){
        setLoading(true)
        const mergedObject = Object.assign({}, _metadata ?? {}, {send_notification : messageChecked});
        const res = await ApiRoute.order.updateStatus.fn(order._id,status , mergedObject)
        if (res?.status_code == 200){
            setStatus(res.results.status)
            setUpdateState(Date.now())
            toast.success("وضعیت سفارش با موفقیت تغییر کرد")
        }
        setLoading(false)
    }

    function needToBeConfirmed(){
        if (process.env.NODE_ENV === 'development'){
            return true;
        }
        return order.status === "process" && order?.factor?.status === "submitted" && order.is_transference && order?.metadata?.inventory_confirmation?.status === "submitted"
    }

    function needToBeConfirmedAccountant(){
        if (process.env.NODE_ENV === 'development'){
            return false;
        }
        let cond = order.status === "process" && order.payment_method.name === "cash_on_delivery" && order?.amounts?.remaining > 0 && order?.metadata?.accountant_confirmation?.status !== "submitted"

        if (!order?.is_shipping_in_person){
            if (cond) {
                if ( order.address.coordinates.state.enname === "Tehran" || order.address.coordinates.state.enname === "Alborz" ){return false;}
            }
            if (cond && ( order.address.coordinates.state.enname !== "Tehran" && order.address.coordinates.state.enname !== "Alborz" ) ){
                return true;
            }
        }

        cond = order.status === "process" && order.payment_method.name === "shaparak" && order?.amounts?.remaining > 0 && order?.metadata?.accountant_confirmation?.status !== "submitted"
        if (cond){ return true; }

        if ( order.payment_method.name !== "shaparak" && order.payment_method.name !== "cash_on_delivery" ){
            cond = order.status === "process" && order?.metadata?.accountant_confirmation?.status !== "submitted"
            if (cond) { return true;}
        }

        return false;
    }

    async function getMessageTemplate(status){
        const res = await ApiRoute.order.getMessageTemplate.fn(order._id, status)
        if (res?.results){
            setMessage(res.results)
        }
    }
    function SubmitButton(){}
    return (

        <div>
            <div
                className="form flex justify-between items-center py-3 border-y border-y-gray-7 mt-4">
                <span>وضعیت سفارش</span>
                <div className="input-group mr-2">
                    <select onChange={ async (e) => {
                        setStatus(e.target.value)
                        setMessage(null)
                        await getMessageTemplate(e.target.value)
                    } } className="input !mr-0">
                        {getStatusList().map( (_status) => <option
                            selected={_status === status}
                            value={_status}>{orderStatusLabel(_status,(order?.is_preorder ?? false))}</option> )}
                    </select>

                    <button onClick={handleUpdate} type="submit"
                            className="btn btn-green text-sm w-16 rounded-l-md p-2">ویرایش
                    </button>


                </div>

            </div>

            {message && <div className={`flex flex-col gap-2`}>
                <textarea className={`border min-h-[150px] border-[#ccc] bg-[#eee] rounded w-full p-2`}>{message}</textarea>
                <label className={`flex items-center gap-2`}>
                    <span>ارسال پیامک</span>
                    <input
                        onChange={ (e) => {
                            if (e.target.checked){
                                setMessageChecked(true)
                            }else{
                                setMessageChecked(false)
                            }
                        } }
                        defaultChecked={messageChecked}
                        type="checkbox"/>
                </label>
            </div>}


            { (modal === "canceled") && <Modal isOpen={true} onClose={() => {
                setModal(null)
            }}><OrderDefinedReason
                order={order}
                status={modal}
                onSuccess={ async () => {
                    await sentToServer()
                    setModal(null)
                }}
                setUpdateState={setUpdateState} /></Modal> }

            <OrderShipmentStatusModal
                openModal={modal === "shipment"}
                setMetadata={ async (d) => {
                    setMetadata(d)
                    await sentToServer(d)
                    setModal(null)
                } }
                loading={loading}
                closeModal={ () => {
                    setModal(null)
                } } order={order} />

        </div>

    )
}