import CustomMultiSelect from "../../Utilities/CustomMultiSelect";
import Modal from "../../Utilities/Modal";
import React, {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {toast} from "react-toastify";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {getTimeFromDate} from "../../../helper/general";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLineChart} from "@fortawesome/free-solid-svg-icons";
import PriceChart from "../../Utilities/PriceChart";

export default function PriceChartModel({product , closeModal}){


    const [data , setData] = useState(null)
    const [update , setUpdate] = useState(0)
    const [priceChart , setPriceChart] = useState([])
    const [datesArray , setDatesArray] = useState([])
    const [finalPricesArray , setFinalPricesArray] = useState([])

    useEffect(() => {
         getData()
    }, [update]);

    async function getData() {
        // product price chart
        const priceResponse = await ApiRoute.product.priceHistory.fn(product._id)
        if (priceResponse.status_code === 200){
            let _priceChart = priceResponse.results;

            let x = Object.keys(_priceChart).map(key => key.split(' ')[0]);
            const currentDate = new Date().toISOString().split('T')[0];
            x.push(currentDate)
            setDatesArray(x)

            const regularPrices = Object.values(_priceChart).map(entry => entry.regular);
            regularPrices.push(regularPrices[regularPrices.length - 1])

            const salePrices = Object.values(_priceChart).map(entry => entry.sale);
            salePrices.push(salePrices[salePrices.length - 1])

            const prices = Object.values(_priceChart).map(entry => entry.price);
            prices.push(prices[prices.length - 1])

            // استفاده مستقیم از مقدار موجودی
            const stockStatus = Object.values(_priceChart).map(entry => entry.stock_web_site);
            stockStatus.push(stockStatus[stockStatus.length - 1])

            setFinalPricesArray({ 
                regular: regularPrices, 
                sale: salePrices, 
                price: prices,
                stock: stockStatus 
            })

            setData(priceResponse)
        }
    }


    return (
        <Modal isOpen={true} onClose={closeModal} classes="">
            <div className="page-title z-1 mb-3 text-13"><span
                className="inline-block text-purple-1 bg-white pl-3 z-2 relative">
                    <FontAwesomeIcon className="ml-1" icon={faLineChart}/>
                    نمودار تغییرات قیمت
                </span>
            </div>
            <p className="text-xs text-gray-10 border-b border-b-gray-7 pb-2">{product.title}</p>
            <div className="flex justify-end gap-5 text-xs">
                        <span className="flex items-center gap-1 text-gray-10">
                            موجود
                            <div className="relative flex items-center">
                                 <span className="inline-block w-5 h-0.5 bg-purple-1 rounded-full"></span>
                                 <span className="inline-block w-1.5 h-1.5 bg-purple-1 rounded-full absolute inset-0 mx-auto top-[-2px]"></span>
                            </div>
                        </span>
                <span className="flex items-center gap-1 text-gray-10">
                            ناموجود
                              <div className="relative flex items-center">
                                 <span className="inline-block w-5 h-0.5 bg-gray-10 rounded-full"></span>
                                 <span className="inline-block w-1.5 h-1.5 bg-gray-10 rounded-full absolute inset-0 mx-auto top-[-2px]"></span>
                            </div>
                        </span>
                <span className="flex items-center gap-1 text-gray-10">
                            تخفیف
                            <span className="inline-block w-1.5 h-1.5 bg-red-1 rounded-full">
                            </span>
                        </span>
            </div>

            {data && <PriceChart 
                labels={datesArray} 
                regularPrices={finalPricesArray.regular} 
                salePrices={finalPricesArray.sale} 
                prices={finalPricesArray.price}
                stockStatus={finalPricesArray.stock}
            />}

        </Modal>
    )
}