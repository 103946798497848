import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";
import ShipmentSelect from "../../../Common/ShipmentSelect";

export default function OrderItemShipmentModal({order, setUpdateState , closeModal}){
    const [update,setUpdate] = useState(0)
    const [data , setData] = useState({

        auto:'1',
        rent_price:'',
        free:'0',
        fixed:'0',
        class:null,

    })

    async function handleSubmit(){
        const res = await ApiRoute.order.item.addShipment.fn(order._id,data)
        if (res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">افزودن حمل و نقل</span></div>
            <div className="form">
                <div className="grid grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-5 gap-4">
                    <label className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">محاسبه اتوماتیک</span>
                        <select onChange={ (e) => {
                            data.auto = e.target.value;
                            setUpdate(Date.now());
                        } } className="!mr-0">
                            <option value="1">بله</option>
                            <option value="0">خیر</option>
                        </select>
                    </label>
                    {/* <label className="!grid">
                        <span className="text-right mb-3 pr-1">پس کرایه</span>
            
                           <select 
                                onChange={(e) => {
                                    data.rent_price = e.target.value;
                                }} 
                                className="!mr-0"
                            >
                                <option value="">ندارد</option>
                                <option value="1">دارد</option>
                            </select>
                    </label> */}
                    <label className="!grid">
                        <span className="text-right mb-3 pr-1">رایگان کردن حمل و نقل</span>
                        <select onChange={ (e) => {
                            data.free = e.target.value;
                        } } className="!mr-0">
                            <option value="0">خیر</option>
                            <option value="1">بله</option>
                        </select>
                    </label>
                    <label className="!grid">
                        <span className="text-right mb-3 pr-1">عدم تغییر حمل و نقل توسط کاربر</span>
                        <select onChange={ (e) => {
                            data.fixed = e.target.value;
                        } } className="!mr-0">
                            <option value="0">خیر</option>
                            <option value="1">بله</option>
                        </select>
                    </label>
                </div>

                {data?.auto != '1' && <div className={`my-4`}>
                    <ShipmentSelect assignItem={(s) => {
                        s.map(ship => {
                            data.class = ship
                        })
                    }} fixed={true}/>
                </div>}

                <div className="grid grid-cols-8 items-end gap-4">
                    <div className="col-span-2">
                        <button onClick={handleSubmit} className="btn-purple py-2 w-full">افزودن</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}