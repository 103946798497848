import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState, useEffect} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import DynamicTabComponent from "../../Utilities/DynamicTabComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select/async';
import { toast } from 'react-toastify';

export default function CreateUtm({closeModal}){
    const [formData, setFormData] = useState({
        campaign_id: '',
        medium_id: '',
        source_id: '',
        term_id: '',
        content_id: '',
        url: '',
        campaign_title: '',
        medium_title: '',
        source_title: '',
        term_title: '',
        content_title: ''
    });
    const [generatedUrl, setGeneratedUrl] = useState('');
    const API_KEY = '123flifhfnARFSrfsfafvhjikK25464F';
    const BASE_URL = 'https://utm.entekhabcenter.com/api';

    const [campaigns, setCampaigns] = useState([]);
    const [mediums, setMediums] = useState([]);
    const [sources, setSources] = useState([]);
    const [terms, setTerms] = useState([]);
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);

    const fetchOptions = async () => {
        setLoading(true);
        try {
            const [campaignsRes, mediumsRes, sourcesRes, termsRes, contentsRes] = await Promise.all([
                fetch(`${BASE_URL}/campaigns?api_key=${API_KEY}`),
                fetch(`${BASE_URL}/media?api_key=${API_KEY}`),
                fetch(`${BASE_URL}/sources?api_key=${API_KEY}`),
                fetch(`${BASE_URL}/term?api_key=${API_KEY}`),
                fetch(`${BASE_URL}/contents?api_key=${API_KEY}`)
            ]);

            const [campaignsData, mediumsData, sourcesData, termsData, contentsData] = await Promise.all([
                campaignsRes.json(),
                mediumsRes.json(),
                sourcesRes.json(),
                termsRes.json(),
                contentsRes.json()
            ]);

            setCampaigns(campaignsData);
            setMediums(mediumsData);
            setSources(sourcesData);
            setTerms(termsData);
            setContents(contentsData);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const handleSelectSubmit = async (e) => {
        e.preventDefault();
        setIsGeneratingUrl(true);
        try {
            const response = await fetch(`${BASE_URL}/generate_utm?api_key=${API_KEY}&campaign_id=${formData.campaign_id}&medium_id=${formData.medium_id}&source_id=${formData.source_id}&term_id=${formData.term_id}&content_id=${formData.content_id}&url=${formData.url}`);
            const data = await response.json();
            setGeneratedUrl(formData.url + "?" + data.data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsGeneratingUrl(false);
        }
    };

    const handleCreateSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch(`${BASE_URL}/generate_utm?api_key=${API_KEY}`, {
                method: 'GET',
                params: {
                    campaign_title: formData.campaign_title,
                    medium_title: formData.medium_title,
                    source_title: formData.source_title,
                    term_title: formData.term_title,
                    content_title: formData.content_title
                }
            });
            if (response.ok) {
                toast.success('با موفقیت ایجاد شد', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // توابع دریافت داده برای هر Select
    const loadCampaigns = async (inputValue) => {
        try {
            const response = await fetch(`${BASE_URL}/campaigns?api_key=${API_KEY}&search=${inputValue}`);
            const data = await response.json();
            return data.map(item => ({
                value: item._id,
                label: item.title
            }));
        } catch (error) {
            console.error('Error loading campaigns:', error);
            return [];
        }
    };

    const loadMediums = async (inputValue) => {
        try {
            const response = await fetch(`${BASE_URL}/media?api_key=${API_KEY}&search=${inputValue}`);
            const data = await response.json();
            return data.map(item => ({
                value: item._id,
                label: item.title
            }));
        } catch (error) {
            console.error('Error loading mediums:', error);
            return [];
        }
    };

    const loadSources = async (inputValue) => {
        try {
            const response = await fetch(`${BASE_URL}/sources?api_key=${API_KEY}&search=${inputValue}`);
            const data = await response.json();
            return data.map(item => ({
                value: item._id,
                label: item.title
            }));
        } catch (error) {
            console.error('Error loading sources:', error);
            return [];
        }
    };

    const loadTerms = async (inputValue) => {
        try {
            const response = await fetch(`${BASE_URL}/term?api_key=${API_KEY}&search=${inputValue}`);
            const data = await response.json();
            return data.map(item => ({
                value: item._id,
                label: item.title
            }));
        } catch (error) {
            console.error('Error loading terms:', error);
            return [];
        }
    };

    const loadContents = async (inputValue) => {
        try {
            const response = await fetch(`${BASE_URL}/contents?api_key=${API_KEY}&search=${inputValue}`);
            const data = await response.json();
            return data.map(item => ({
                value: item._id,
                label: item.title
            }));
        } catch (error) {
            console.error('Error loading contents:', error);
            return [];
        }
    };

    // تنظیمات مشترک برای همه Select ها
    const customStyles = {
        control: (base) => ({
            ...base,
            minHeight: '42px',
            borderRadius: '0.375rem',
            borderColor: '#E5E7EB',
            '&:hover': {
                borderColor: '#E5E7EB'
            }
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999
        })
    };

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('لینک با موفقیت کپی شد', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (err) {
            toast.error('خطا در کپی کردن لینک', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <Modal isOpen={true} onClose={closeModal}
               modalWidth="w-[50%]">
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">لینک یو تی ام</span>
            </div>
            <DynamicTabComponent>
                <div title="انتخاب">
                    <form onSubmit={handleSelectSubmit} className="form grid-form w-[300px] md:w-full text-xs grid gap-5">
                        <div>
                            <label>انتخاب کمپین</label>
                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadCampaigns}
                                onChange={(option) => handleInputChange({
                                    target: { name: 'campaign_id', value: option.value }
                                })}
                                placeholder="جستجو کنید..."
                                noOptionsMessage={() => "موردی یافت نشد"}
                                loadingMessage={() => "در حال جستجو..."}
                                styles={customStyles}
                            />
                        </div>
                        <div>
                            <label>انتخاب مدیوم</label>
                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadMediums}
                                onChange={(option) => handleInputChange({
                                    target: { name: 'medium_id', value: option.value }
                                })}
                                placeholder="جستجو کنید..."
                                noOptionsMessage={() => "موردی یافت نشد"}
                                loadingMessage={() => "در حال جستجو..."}
                                styles={customStyles}
                            />
                        </div>
                        <div>
                            <label>انتخاب سورس</label>
                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadSources}
                                onChange={(option) => handleInputChange({
                                    target: { name: 'source_id', value: option.value }
                                })}
                                placeholder="جستجو کنید..."
                                noOptionsMessage={() => "موردی یافت نشد"}
                                loadingMessage={() => "در حال جستجو..."}
                                styles={customStyles}
                            />
                        </div>
                        <div>
                            <label>انتخاب ترم</label>
                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadTerms}
                                onChange={(option) => handleInputChange({
                                    target: { name: 'term_id', value: option?.value || '' }
                                })}
                                placeholder="جستجو کنید..."
                                noOptionsMessage={() => "موردی یافت نشد"}
                                loadingMessage={() => "در حال جستجو..."}
                                styles={customStyles}
                                isClearable
                            />
                        </div>
                        <div>
                            <label>انتخاب کانتنت</label>
                            <Select
                                cacheOptions
                                defaultOptions
                                loadOptions={loadContents}
                                onChange={(option) => handleInputChange({
                                    target: { name: 'content_id', value: option?.value || '' }
                                })}
                                placeholder="جستجو کنید..."
                                noOptionsMessage={() => "موردی یافت نشد"}
                                loadingMessage={() => "در حال جستجو..."}
                                styles={customStyles}
                                isClearable
                            />
                        </div>
                        <div>
                            <label>لینک شما</label>
                            <input name="url" value={formData.url} onChange={handleInputChange} className="w-full" type="text" required/>
                        </div>
                        <div className="flex justify-end mt-4">
                            <button 
                                type="submit" 
                                className="btn-purple px-5 py-2"
                                disabled={isGeneratingUrl}
                            >
                                {isGeneratingUrl ? 'در حال ایجاد...' : 'ایجاد لینک'}
                            </button>
                        </div>
                        {generatedUrl && (
                            <div className="flex justify-between items-center rounded-md bg-gray-7 text-xs p-2 mt-4">
                                <button 
                                    className="bg-purple-1 text-white rounded pt-1 pb-0.5 px-2"
                                    onClick={() => handleCopy(generatedUrl)}
                                >
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                <span className="text-left" dir="ltr">{generatedUrl}</span>
                            </div>
                        )}
                    </form>
                </div>
                <div title="ایجاد">
                    <form onSubmit={handleCreateSubmit} className="form grid-form w-[300px] md:w-full text-xs grid gap-5">
                        <div>
                            <label>عنوان کمپین</label>
                            <input name="campaign_title" value={formData.campaign_title} onChange={handleInputChange} className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان مدیوم</label>
                            <input name="medium_title" value={formData.medium_title} onChange={handleInputChange} className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان سورس</label>
                            <input name="source_title" value={formData.source_title} onChange={handleInputChange} className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان ترم</label>
                            <input name="term_title" value={formData.term_title} onChange={handleInputChange} className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان کانتنت</label>
                            <input name="content_title" value={formData.content_title} onChange={handleInputChange} className="w-full" type="text"/>
                        </div>
                        <div className="flex justify-end mt-4">
                            <button 
                                type="submit" 
                                className="btn-purple px-5 py-2" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'در حال ارسال...' : 'ایجاد درخواست'}
                            </button>
                        </div>
                    </form>
                </div>
            </DynamicTabComponent>
        </Modal>
    )
}