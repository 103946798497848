import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload, faEye} from "@fortawesome/free-solid-svg-icons";
import {getDateAsReadableFormat} from "../../helper/general";
import ApiRoute from "../../helper/api";
import { Link } from 'react-router-dom';


function FormsTable(props) {
    const tableTitles = [
        'عنوان فرم',
        'تعداد ورودی',
        'تاریخ بروزرسانی فرم',
        'تاریخ ایجاد فرم',
    ]
    async function handleExport(form){
        const res = await ApiRoute.form.export.fn(form._id)
        if (res.results[0]['url']){
            window.open(res.results[0]['url'])
        }
    }
    return (
        <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((tableData, id) => (
                        <tr className={`text-xs py-2`} key={id}>
                            <td>{tableData.title}</td>
                            <td>{tableData.inbox_count}</td>
                            <td>{getDateAsReadableFormat(tableData.updated_at ,true)}</td>
                            <td>{getDateAsReadableFormat(tableData.created_at,true)}</td>

{/*                             <td className="flex justify-center items-center py-3">
                                <button className="mx-2" onClick={()=>{handleExport(tableData)}} title="دریافت ورودی ها"><FontAwesomeIcon
                                    className="bg-orange-2 text-white p-1 w-4 h-4 rounded" icon={faDownload}/></button>
                            </td> */}

                            <td className="flex justify-center items-center py-3">
                                <Link className="mx-2" to={`/forms/view?id=${tableData._id}`} title="مشاهده ورودی ها"><FontAwesomeIcon
                                    className="bg-orange-2 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                            </td>

                        </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default FormsTable