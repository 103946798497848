import Layout from "../components/Layout"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faBagShopping,
    faCashRegister,
    faMoneyBill, faStore,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import Toman from "../components/Utilities/Toman";
import ShopInfo from "../components/Dashboard/ShopInfo";
import ProductInfo from "../components/Dashboard/ProductInfo";
import LatestOrder from "../components/Dashboard/LatestOrder";
import CustomerWidget from "../components/Dashboard/Widget/CustomerWidget";
import OrderWidget from "../components/Dashboard/Widget/OrderWidget";
import SellWidget from "../components/Dashboard/Widget/SellWidget";
import PaymentWidget from "../components/Dashboard/Widget/PaymentWidget";
import DebitCardPaymentWidget from "../components/Dashboard/Widget/DebitCardPaymentWidget";
import {HasAccessComponent} from "../helper/permissions";
import { isPrimaryShop } from "../helper/api";
import OrderList from "../components/Order/OrderList";

function Dashboard() {
    return (
        <Layout>
            <div>
                <span className="inline-block bg-white px-2">پیشخوان</span>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mt-5">
                    <HasAccessComponent permission={true} children={<CustomerWidget/>}/>
                    <HasAccessComponent permission={true} children={<OrderWidget/>}/>
                    <HasAccessComponent permission={true} children={<SellWidget/>}/>
                    <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="shaparak" title="پرداخت آنلاین"/>}/>
                    {/* <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="cash_on_delivery" title="پرداخت در محل"/>}/> */}
                    <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="debit_card" title="واریز وجه کارت به کارت"/>}/>
{/*                     <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="point_of_sale" title="دستگاه پوز"/>}/>
                    <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="ezpay" title="ایزپی"/>}/>
                    <HasAccessComponent permission={true} children={<PaymentWidget paymentMethod="cheque" title="چک"/>}/> */}
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 mt-5">
                    <div className="lg:col-span-3">
                        <HasAccessComponent permission={true} children={<LatestOrder/>}/>
                    </div>
                    <div className="grid gap-5">
                        {isPrimaryShop() && <HasAccessComponent permission={true} children={<ProductInfo/>}/>}
                        <HasAccessComponent permission={true} children={<ShopInfo/>}/>
                    </div>
                </div>
            </div>
            {/* {!isPrimaryShop() &&  <OrderList/>} */}
        </Layout>
    );
}

export default Dashboard;