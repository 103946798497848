import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faCopy, faBarsStaggered, faSortDown, faEdit} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-regular-svg-icons'
import SwitchToggle from "../Utilities/SwitchToggle";
import {useState} from "react";
import {Link} from "react-router-dom";
import {formatNumberWithDots} from "../../helper/general";
import ApiRoute from "../../helper/api";

function CouponTable({tableData, setUpdateData}) {
    const tableTitles = [
        'عنوان مدیریتی',
        'عنوان مشتری',
        'کد تخفیف',
        'میزان',
    ]
    const [isToggled, setToggled] = useState(null);

    const toggleSubgroup = (id) => setToggled(id);
    return (
        <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
                {tableData.map((tableData, id) => (
                    <>
                        <tr className={`text-xs py-2`} key={tableData._id}>
                            <td>
                                {tableData.admin_title}
                                {tableData.variants.length > 0 && <button className="block text-center mx-auto text-gray-3 mt-1"
                                                                          onClick={() => toggleSubgroup(isToggled !== id ? id : null)}>
                                    <FontAwesomeIcon icon={faSortDown} className="ml-2"/>
                                    زیر گروه ها
                                </button>}
                            </td>
                            <td>{tableData.title}</td>
                            <td style={{fontFamily:"sans-serif"}}>{tableData.code}</td>
                            <td>{formatNumberWithDots(tableData.amount)}</td>
                            <td className="flex justify-center items-center py-3">
                                    <SwitchToggle
                                    onSwitch={ async (c) => {
                                        await ApiRoute.coupon.updateEnabled.fn(c,tableData._id)
                                        setUpdateData(Date.now())
                                    } }
                                    onOff={tableData.active}/>
{/*                                <button className="mx-2" title="؟؟؟" onClick={() => toggleSubgroup(isToggled !== id ? id : null)}><FontAwesomeIcon
                                    className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faBarsStaggered}/></button>*/}
                                <Link className="mx-2" to={ tableData?.variants?.length > 0 ? `/coupons/view?id=${tableData._id}` : `/coupons/edit?id=${tableData._id}`} title="مشاهده"><FontAwesomeIcon
                                    className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                <button className="mx-2" onClick={ async () => {
                                    await ApiRoute.coupon.clone.fn(tableData._id)
                                    setUpdateData(Date.now())
                                } } title="کلون"><FontAwesomeIcon
                                    className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faCopy}/></button>
{/*                                <Link className="mx-1" to="/coupons/edit" title="ویرایش"><FontAwesomeIcon
                                    className="bg-orange-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>*/}
                                <button className="mx-2" onClick={ async () => {
                                    if (!window.confirm('آیا از حذف این کد تخفیف اطمینان دارید؟')) {
                                        return;
                                    }
                                    await ApiRoute.coupon.delete.fn(tableData._id)
                                    setUpdateData(Date.now())
                                } } title="حذف کد تخفیف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </td>

                        </tr>

                        {tableData.variants.length > 0 && tableData.variants.map(variant => (
                            isToggled === id && <tr key={variant._id} className={`sub-groups text-xs show`}>
                                <td>{variant.admin_title}</td>
                                <td></td>
                                <td></td>
                                <td>{formatNumberWithDots(variant.amount)}</td>
                                <td className="flex justify-center items-center py-2">
                                    <SwitchToggle  onSwitch={ async (c) => {
                                        await ApiRoute.coupon.updateEnabled.fn(c,variant._id)
                                        setUpdateData(Date.now())
                                    } } onOff={variant.active}/>
                                    <Link className="mx-2" to={`/coupons/edit?id=${variant._id}`} title="مشاهده"><FontAwesomeIcon
                                        className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                    <button className="mx-2" onClick={ async () => {
                                        await ApiRoute.coupon.clone.fn(variant._id)
                                        setUpdateData(Date.now())
                                    } } title="کلون"><FontAwesomeIcon
                                        className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faCopy}/></button>
                                    <button className="ml-2" onClick={ async () => {
                                        if (!window.confirm('آیا از حذف این کد تخفیف اطمینان دارید؟')) {
                                            return;
                                        }
                                        await ApiRoute.coupon.delete.fn(variant._id)
                                        setUpdateData(Date.now())
                                    } } title="حذف کد تخفیف"><FontAwesomeIcon
                                        className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                </td>
                            </tr>

                        ))}
                    </>
                )
            )}
            </tbody>
        </table>
    )
}

export default CouponTable